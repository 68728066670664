import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./orbitmart-logo.png";
import NewLogo from "../../data/orbit.png";
import { Link } from "react-router-dom";
//Login
import { useRef, useState, useEffect, useContext } from "react";
import StateContext from "../../contexts/ContextProvider";
import auth from "../../contexts/reducers/auth";
import { showLoader } from "../../components/helper/loading";
import axios from 'axios'
import { showErrorMsg } from "../../components/helper/message";
const theme = createTheme();

const Login = () => {
  const { setAuth } = useContext(StateContext);
  const { auth } = useContext(StateContext);

  const { setLoginData,setProfileData,setReset } = useContext(StateContext);
  const { loginData } = useContext(StateContext);
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }
  // console.log(setAuth);
  const userRef = useRef();
  const errRef = useRef();

  let isLoggedIn = false;

  //Token
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");

  const [success, setSuccess] = useState(false);
  const [statuscheck, setStatusCheck] = useState(false);
  const [res, setRes] = useState({});
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  useEffect(() => {
    if (statuscheck) {
      statusCheck();
    }
  }, [statuscheck]);

  async function statusCheck() {
    try {
      let distriId = sessionStorage.getItem("id");
      let status = { ActiveInactive: true };
      let res = await fetch(
        `http://143.244.132.221:4002/api/v1/auth/update/status/${distriId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(status),
        }
      );
      res = await res.json();
      console.log(res);
      if (res.code == 200) {
        setAuth(true);
        console.log(auth);
        <Link to="/distributor" />;
      }
    } catch (response) {
      console.log(response);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const username=data.get("username");
      const password=data.get("password");
if(!username&&!password)
{
  setMsg("All fields are required");
  timeout = displayErrMsg(setErrMsg, timeout);
}
else if(!username)
{
  setMsg("Username is required");
  timeout = displayErrMsg(setErrMsg, timeout);
}
else if(!password)
{
  setMsg("Password is required");
  timeout = displayErrMsg(setErrMsg, timeout);
}
else
{
     
      const finalData = {
        username: username,
        password: password,
        role: "DISTRIBUTOR",
      };
      // console.log(finalData);
      let config={headers: {"Content-Type": "application/json"}}
      setLoading(true);
      await axios.post("http://143.244.132.221:4002/api/v1/auth/login",finalData,config).then(async(res)=>{
        if (res.data.status === "SUCCESS") {
            sessionStorage.setItem("myToken", res.data.data.token);
            sessionStorage.setItem("id", res.data.data._id);
           
            let config={
                  headers: {
                    "Content-Type": "application/json",
                    "x-token":res.data.data.token,
                  }}
                  await axios.put(`http://143.244.132.221:4002/api/v1/auth/edit-profile`,{WEB_TOKEN:sessionStorage.getItem('FCM_TOKEN')},config).then((res)=>{
                    setStatusCheck(true);
                    setLoading(false);     
                  }                           
                  )
              
        }
        else{
            // setMsg(res.data.message);
            setMsg("Invalid login credentials");
            timeout = displayErrMsg(setErrMsg, timeout);
            setLoading(false)
        }
      })








      // let result = await fetch("http://143.244.132.221:4002/api/v1/auth/login", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //   },
      //   body: JSON.stringify(finalData),
      // });
      // result = await result.json();
      // console.log(result);
      
      // if (result.code == 200) {
      //   let token = result.data.token;
      //   setStatusCheck(true);
      //   sessionStorage.setItem("myToken", token);
      //   let id = result.data._id;
      //   sessionStorage.setItem("id", id);
      //   let config={
      //     headers: {
      //       "Content-Type": "application/json",
      //       "x-token":token,
      //     }}
      //     console.log(token)
      //   await axios.put(`http://143.244.132.221:4002/api/v1/auth/edit-profile`,{WEB_TOKEN:sessionStorage.getItem('FCM_TOKEN')},config).then(async(res)=>
      //   {
      //     // await axios
      //     // .get(`http://143.244.132.221:4002/api/v1/auth/get-profile`, config)
         
      //   })
      // }
    }
    } catch (Response) {
      console.log(Response.message);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {errMsg && showErrorMsg(msg, errMsg)}
        <Box
          sx={{
            //marginTop: 20,
            //margin: "auto",
            marginTop: "23%",
            marginBottom: "23%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "25px",
            padding: 2,
            border: "2px solid #003c7e",
          }}
        >
          <img src={NewLogo} style={{ height: "50%" }} />
          <h1
            style={{
              fontFamily: "Helvetica",
              fontWeight: "bold",
              fontSize: "20px",
              color: "#003c7e",
              marginTop: 8,
            }}
          >
            Distributor Login
          </h1>
          {loading && showLoader()}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              type="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
             <p className="policy">
                    By continuing, you agree to OrbitMart's{" "}
                   
                    <Link className="lk" to="/privacypolicy" target="_blank"  >
                    Terms of Use and
              Privacy Policy
            </Link>
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
                        
        <Link  onClick={()=>setReset(true)} to="/"   >
         Forgot Password?
          </Link>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;

// import * as React from "react";
// // import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// // import FormControlLabel from "@mui/material/FormControlLabel";
// // import Checkbox from "@mui/material/Checkbox";
// import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";
// // import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// // import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import logo from "./orbitmart-logo.png";
// // import cors from "cors";
// // import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";

// //Login
// import { useRef, useState, useEffect, useContext } from "react";
// import StateContext from "../../contexts/ContextProvider";
// import auth from "../../contexts/reducers/auth";
// import axios from "axios";
// // import axios from "../../api/axios";
// // const LOGIN_URL = "/login";

// // function Copyright(props) {
// //   return (
// //     <Typography
// //       variant="body2"
// //       color="text.secondary"
// //       align="center"
// //       {...props}
// //     >
// //       <Link color="inherit" href="https://mui.com/"></Link>
// //     </Typography>
// //   );
// // }

// const theme = createTheme();

// const Login = () => {
//   const { setAuth } = useContext(StateContext);
//   const { auth } = useContext(StateContext);
//   console.log(setAuth);
//   const userRef = useRef();
//   const errRef = useRef();

//   let isLoggedIn = false;

//   //Token
//   const [user, setUser] = useState("");
//   const [pwd, setPwd] = useState("");
//   const [errMsg, setErrMsg] = useState("");
//   const [success, setSuccess] = useState(false);
//   const [statuscheck, setStatusCheck] = useState(false);
//   const [res, setRes] = useState({});

//   useEffect(() => {
//     setErrMsg("");
//   }, [user, pwd]);

//   useEffect(() => {
//     if (statuscheck) {
//       statusCheck();
//     }
//   }, [statuscheck]);
//   async function statusCheck() {
//     try {
//       let distriId = localStorage.getItem("id");
//       let status = { ActiveInactive: true };
//       let res = await fetch(
//         `http://143.244.132.221:4002/api/v1/auth/update/status/${distriId}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//           },
//           body: JSON.stringify(status),
//         }
//       );
//       res = await res.json();
//       console.log(res);
//       if (res.code == 200) {
//         setAuth(true);
//         console.log(auth);
//         <Link to="/distributor" />;
//       }
//     } catch (response) {
//       console.log(response);
//     }
//   }
//   async function handleSubmit(e) {
//     e.preventDefault();
//     try {
//       const data = new FormData(e.currentTarget);
//       const finalData = {
//         username: data.get("username"),
//         password: data.get("password"),
//         role: "DISTRIBUTOR",
//       };
//       console.log(finalData);
//       let result = await fetch("http://143.244.132.221:4002/api/v1/auth/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//         body: JSON.stringify(finalData),
//       });
//       result = await result.json();
//       console.log(result);
//       if (result.code == 200) {
//         let token = result.data.token;
//         setStatusCheck(true);
//         sessionStorage.setItem("myToken", token);
//         let id = result.data._id;
//         localStorage.setItem("id", id);
//       }
//     } catch (Response) {
//       console.log(Response.message);
//     }
//   }

//   const clickHandle = (e) => {
//     console.log(e.password.value);
//     console.log(e.email.value);
//   };

//   const registerPage = () => {
//     const navigate = useNavigate();

//     isLoggedIn(true);
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />

//         <Box
//           sx={{
//             marginTop: 20,
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             borderRadius: "25px",
//             padding: 2,
//             border: "2px solid black",
//           }}
//         >
//           <img src={logo} style={{ marginTop: "13px", height: "90px" }} />
//           <h2>
//             <b>Distributor</b>
//           </h2>
//           <Box
//             component="form"
//             onSubmit={handleSubmit}
//             noValidate
//             sx={{ mt: 1 }}
//           >
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               id="username"
//               label="Email Address"
//               name="username"
//               type="email"
//               autoComplete="email"
//               autoFocus
//             />
//             <TextField
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type="password"
//               id="password"
//               autoComplete="current-password"
//             />
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Sign In
//             </Button>
//           </Box>
//         </Box>
//       </Container>
//     </ThemeProvider>
//   );
// };

// export default Login;
