import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

const ViewEmployeeWiseOrders = () => {
  const [view, setView] = useState();
  const [successData, setSuccessData] = useState();
  const distId = sessionStorage.getItem("id");
  const { id } = useParams();
  console.log("TableDataID", id);
  // c - 0;

  async function fetchData() {
    axios
      .get(`http://143.244.132.221:4002/api/v1/order/user/${distId}`)
      .then((res) => {
        // console.log("first++", res.data.data);
        setSuccessData(res.data.data);
        console.log("brrro",res.data.data )
      })
      .catch((err) => {
        console.log("error++++", err.message);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="container">
      {successData?.map((item, index)=>{
       return (
<div key={index}>
{item._id === id ? (
     
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div class="flex justify-end active">
          <div class="backIcon">
            <Link to="/employeewiseorders">
              <AiOutlineRollback />
            </Link>
          </div>
        </div>
        <div class="flex justify-center text-left">
          <ul class="bg-white rounded-lg w-96 text-gray-900 ">
            <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg ">
              Category : Subscription
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Order No :{item?.orderNo}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Order Date : {item?.createdAt}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Client : {item?.userId?.firstName}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Employee : {item?.employeeId?.firstName}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Order Amount : 
              
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Employee Amount : {item?.userId?.totalPrice}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Order Status : {item?.paymentStatus}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Delivery Date : {item?.date}
            </li>
            <li class="px-6 py-2 w-full rounded-b-lg">Distance : 30 </li>
          </ul>
        </div>
      </div>
      ) : null}
      </div>
      );
       })}
    </div>
  );
};

export default ViewEmployeeWiseOrders;
