import React, {useState, useEffect} from "react";
import { BsCurrencyDollar, BsJustify } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import { IoIosMore } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { Stacked, Pie, Button, LineChart, SparkLine } from "../../components";
import {
  earningData,
  medicalproBranding,
  recentTransactions,
  weeklyStats,
  dropdownData,
  SparklineAreaData,
  ecomPieChartData,
} from "./DitributorConfig";
import { useStateContext } from "../../contexts/ContextProvider";
import product9 from "./product9.jpg";
import { CChart } from "@coreui/react-chartjs";
import { CChartBar } from "@coreui/react-chartjs";
import { AxesDirective } from "@syncfusion/ej2-react-charts";

const DropDown = ({ currentMode }) => (
  <div className="w-28 border-1 border-color px-2 py-1 rounded-md">
    <DropDownListComponent
      id="time"
      fields={{ text: "Time", value: "Id" }}
      style={{ border: "none", color: currentMode === "Dark" && "white" }}
      value="1"
      dataSource={dropdownData}
      popupHeight="220px"
      popupWidth="120px"
    />
  </div>
);

const Ecommerce = () => {
  const { currentColor, currentMode } = useStateContext();
  const [orderData, setOrderData] = useState([]);
  const distId = sessionStorage.getItem("id");
  const handleOrderData = async() => {
    try {
     const response = await axios.get(`http://143.244.132.221:4002/api/v1/order`)
     const orderDataFilter = response.data.data.filter(data => data.distributorId == distId).reverse();
     setOrderData(orderDataFilter)
    }catch(err) {console.error(err);}
  }
  useEffect(() => {
    handleOrderData()
  },[])

  const generateWeekOptions = () => {
    const weeks = ["1st Week", "2nd Week", "3rd Week", "4th Week"];
    return weeks.map((week, index) => (
      <option key={index} value={index+1}>{week}</option>
    ));
  };

  const orderStatusData = orderData.map(orderData =>orderData.orderStatus)
  const orderStatusDataPending = orderData.filter(orderData =>orderData.orderStatus == "PENDING")
  const orderStatusDataPROCESSING = orderData.filter(orderData =>orderData.orderStatus == "PROCESSING")
  const orderStatusDataPACKAGING = orderData.filter(orderData =>orderData.orderStatus == "PACKAGING")
  const orderStatusDataSHIPPING = orderData.filter(orderData =>orderData.orderStatus == "SHIPPING")
  const orderStatusDataCOMPLETED = orderData.filter(orderData =>orderData.orderStatus == "COMPLETED")
  const orderStatusDataREJECTED = orderData.filter(orderData =>orderData.orderStatus == "REJECTED")
  const orderStatusDataCANCELLED = orderData.filter(orderData =>orderData.orderStatus == "CANCELLED")
  return (
    <div className="mt-24">
      <div className="d-flex flex-wrap lg:flex-nowrap justify-left ">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "20px",
          }}
        >
          <div>
            <div style={{ margin: "10% 0" }}>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    Total Order
                  </span>
                  <span>{orderStatusData ? orderStatusData.length : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PENDING
                  </span>
                  <span>{orderStatusDataPending ? orderStatusDataPending.length : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PROCESSING
                  </span>
                  <span>{orderStatusDataPROCESSING ? orderStatusDataPROCESSING.length : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    PACKAGING
                  </span>
                  <span>{orderStatusDataPACKAGING ? orderStatusDataPACKAGING.length : 0}</span>
                </div>
              </div>

              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    SHIPPING
                  </span>
                  <span>{orderStatusDataSHIPPING ? orderStatusDataSHIPPING.length : 0}</span>
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    COMPLETE
                  </span>
                  <span>{orderStatusDataCOMPLETED ? orderStatusDataCOMPLETED.length : 0}</span>
                </div>
              </div>
              <div
                style={{
                  boxShadow:
                    "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                  width: "350px",
                }}
                className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-xl w-full lg:w-60 p-2 m-1   bg-no-repeat bg-cover bg-center"
              >
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-gray-400">
                    REJECT/CANCEL
                  </span>
                  <span>{`${orderStatusDataREJECTED ? orderStatusDataREJECTED.length : 0}/${orderStatusDataCANCELLED ? orderStatusDataCANCELLED.length : 0}`}</span>
                </div>
              </div>
            </div>
          </div>
          <div style={{width: '40%'}}>
            <div style={{ display: "flex"}}>
              <div style={{ display: "flex",width: '100%' }} className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center ">
                <div>
                  <h1 style={{fontSize: '20px', fontWeight: "bold"}}>Order</h1>
                </div>
                <CChart
                  type="doughnut"
                  data={{
                    labels: [
                      "Total Pending Order",
                      "Total Processing Order",
                      "Total Packaging Order",
                      "Total Shipping Order",
                      "Total Complete Order",
                      "Total Reject Order",
                    ],
                    datasets: [
                      {
                        backgroundColor: ["#FDE767","#F3B95F", "#6895D2", "#00D8FF", "#41B883", "#D04848"],
                        data: [orderStatusDataPending.length, orderStatusDataPROCESSING.length, orderStatusDataPACKAGING.length, orderStatusDataSHIPPING.length, orderStatusDataCOMPLETED.length, orderStatusDataREJECTED.length],
                      },
                    ],
                  }}
                />
              </div>

              {/* <div class="card" style={{ width: "400px", height: "1000px"  }}> */}
              {/* <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-94 rounded-xl w-full lg:w-80 p-4 pt-5 m-1   bg-no-repeat bg-cover bg-center">
                <div>
                  <h1>Sellers</h1>
                </div>
                <CChart
                  type="doughnut"
                  data={{
                    labels: [
                      "Total approved sellers ",
                      "Total sellers                ",
                      "Total pending sellers   ",
                    ],
                    datasets: [
                      {
                        backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                        data: [10, 60, 80],
                      },
                    ],
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>

        <div style={{display:'flex', justifyContent: 'center'}}>
          <div className="bg-white dark:text-black-200 dark:bg-secondary-dark-bg h-90 rounded-xl w-full lg:w-900 p-8 pt-9 m-6  bg-no-repeat bg-cover bg-center">
            {/* <div style={{display:"flex"}}>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Oriz</option>
  <option value="2">counter sell</option>
  <option value="3">personal app</option>
</select></div><br/>

<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">Cancel</option>
  <option value="2">Pending</option>
  <option value="3">Processing</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
<div><select class="form-select" aria-label="Default select example">
  <option selected>All</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select></div>
</div> */}
            <div style={{ display: "flex" }}>

              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Order status</label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="1">Cancel</option>
                  {/* <option value="2">Pending</option>
                  <option value="3">Processing</option> */}
                </select>
              </div>

              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Payment status </label>
                <select
                  class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>All</option>
                  <option value="MALE">pending</option>
                  <option value="FEMALE">Complete</option>
                </select>
              </div>

              {/* <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Month/Year</label>
                <input
                  type="month"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Month/Year"
                />
              </div> */}
<div class="relative z-0 p-2 w-full mb-6 group">
                <label>Week</label>
                <div style={{display: "flex", gap:"8px"}}>
                <select className="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Week/Month/Year">
          <option value="week">Week</option>
          {generateWeekOptions()}
                </select>
                </div>
               
              </div>
 
              <div class="relative z-0 p-2 w-full mb-6 group">
                <label>Month/Year</label>
                <div style={{display: "flex", gap:"8px"}}>
                <input
                  type="month"
                  class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                  placeholder="Enter Month/Year"
                />
               
                </div>
               
              </div>
            </div>
            <CChartBar
              data={{
                labels: [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ],
                datasets: [
                  {
                    label: "Restaurant Order Statistics",
                    backgroundColor: "#f87979",
                    data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
                  },
                ],
              }}
              labels="months"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecommerce;
