import React,{useState,useContext} from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import NewLogo from "../../data/orbit.png";
import { Link } from "react-router-dom";

import { showLoader } from "../../components/helper/loading";
import axios from 'axios'
import { showErrorMsg } from "../../components/helper/message";
import StateContext from "../../contexts/ContextProvider";
import { GrSettingsOption } from "react-icons/gr";
const theme = createTheme();

const ForgotPassword = () => {
  const { setReset,setRegisterInfo,setDisplayOTP}=useContext(StateContext) 
  const [errMsg, setErrMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/; // Change this regex based on requirement
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const data = new FormData(e.currentTarget);
      const mobile=data.get("mobile");

if(!mobile)
{
  setMsg("Mobile Number is required");
  timeout = displayErrMsg(setErrMsg, timeout);
}
else if(!phoneRegex.test(mobile))
{
  setMsg("Mobile Number Must be 10 digit");
  timeout = displayErrMsg(setErrMsg, timeout);
}
else{
  // setMsg("ok");
  // timeout = displayErrMsg(setErrMsg, timeout);
  resendOTP(mobile)



}
    }
catch (Response) {
  console.log(Response.message);
}
}


const resendOTP = async (mobile) => {
  setLoading(true)
  await axios.get(`http://143.244.132.221:4002/api/v1/auth/resend/otp/${mobile}`).then((res) => {
    if (res.data.data == undefined) {
      setMsg("You are not registered with us.Please sign up");
      

      // setMsg(res.data.message);
      timeout = displayErrMsg(setErrMsg, timeout);
     
    } else {
      setLoading(false)
      setRegisterInfo({ otp: res.data.data, mobile: mobile });
      setReset(false)
      setDisplayOTP(true)
    }
  });
};
  return (
    <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {errMsg && showErrorMsg(msg, errMsg)}
      <Box
        sx={{
          //marginTop: 20,
          //margin: "auto",
          marginTop: "23%",
          marginBottom: "23%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "25px",
          padding: 2,
          border: "2px solid #003c7e",
        }}
      >
        <img src={NewLogo} style={{ height: "50%" }} />
        <h1
          style={{
            fontFamily: "Helvetica",
            fontWeight: "bold",
            fontSize: "20px",
            color: "#003c7e",
            marginTop: 8,
          }}
        >
          Forgot Password
        </h1>
        {loading && showLoader()}
        <br/>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        ><br/>
          <p >Please enter your mobile number. You will receive a otp to  reset  your password .</p><br/>
          <TextField
            margin="normal"
            required
            fullWidth
            id="mobile"
            label="Mobile Number"
            name="mobile"
            type="number"
            autoComplete="mobile"
            autoFocus
          />
       
           <p className="policy">
                  By continuing, you agree to OrbitMart's{" "}
                 
                  <Link className="lk" to="/privacypolicy" target="_blank"  >
                  Terms of Use and
            Privacy Policy
          </Link>
          </p>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
           Reset Password
          </Button>
        </Box>
        <Link  onClick={()=>setReset(false)} to="/"   >
        Remember your password?
          </Link>
      </Box>
    </Container>
  </ThemeProvider>
  )
}

export default ForgotPassword