import { Link } from "react-router-dom";
import { AiOutlineFolderView } from "react-icons/ai";
// import { MdPageview } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import DeleteUser from "./DeleteUser";


const gridItemAction = (itemId) => (
  <div className="flex items-center justify-center gap-2">
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-xl mt-0 mb-2 "
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to={`/appUsers/view/${itemId._id}`}
       
      >
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
       <button
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <DeleteUser itemId={itemId._id} />
      </button>
    </h1>
  </div>
);

const gridStatus = (props) => (
  <button
    type="button"
    style={{ background: props.ClientStatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.ClientStatus}
  </button>
);

export const itemsGrid = [
  {
    field: "_id",
    headerText: "Id",
    isPrimaryKey: true,
    textAlign: "Center",
  },
  {
    field: "userId.firstName",
    headerText: "Client Name",
    textAlign: "Center",
  },
  {
    field: "userId.mobile",
    headerText: "Mobile No",
    textAlign: "Center",
  },
  {
    field: "userId.email",
    headerText: "Email",
    textAlign: "Center",
  },
  // {
  //   field: "gender",
  //   headerText: "Gender",
  //   textAlign: "Center",
  // },
  {
    field: "pincode",
    headerText: "Pincode",
    textAlign: "Center",
  },
  // {
  //   field: "registeredmedia",
  //   headerText: "Registered Media",
  //   textAlign: "Center",
  // },
  {
    field: "createdAt",
    headerText: "Registered At",
    textAlign: "Center",
  },
  {
    headerText: "Client Status",
    template: gridStatus,
    field: "id",
    textAlign: "Center",
  },
  {
    field: "action",
    headerText: "Actions",
    textAlign: "Center",
    template: gridItemAction,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 2,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Inactive",
    ClientStatusBg: "#FF5C8E",
  },
  {
    id: 3,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 4,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Inactive",
    ClientStatusBg: "#FF5C8E",
  },
  {
    id: 5,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 6,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Inactive",
    ClientStatusBg: "#FF5C8E",
  },
  {
    id: 7,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Inactive",
    ClientStatusBg: "#FF5C8E",
  },
  {
    id: 8,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 9,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 10,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 11,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 12,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 13,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 14,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 15,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 16,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 17,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 18,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 19,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
  {
    id: 20,
    clientname: "Rajat Shukla",
    mobileno: 9898949494,
    email: "rajat@gmail.com",
    gender: "Male",
    zipcode: 416520,
    registeredmedia: "app",
    registeredate: "2022-05-05 18:20:00",
    ClientStatus: "Active",
    ClientStatusBg: "#8BE78B",
  },
];
