import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {Button} from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import "../../pages/view.css";
import { BiSearch } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import DeleteRate from "./DeleteRate";
import { Link } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
// import { itemsData, contextMenuItems, itemsGrid } from "../../data/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { itemsGrid, itemsData, contextMenuItems } from "./DeliveryRateConfig";
import axios from "axios";
// import StateContext from "../../contexts/ContextProvider";
import StateContext from "../../contexts/ContextProvider";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "center",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "center",
  },
}));


const DeliveryRate = (itemId) => {
  const distId = sessionStorage.getItem("id");
  const [orderData, setOrderData] = useState([]);
  const { setViewData } = useContext(StateContext );
  const [searchQuery, setSearchQuery] = useState('');
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(5);
  const totalPages = Math.ceil(orderData.length / pageSize); 
  const [loading, setLoading] = useState(false);

 const fetchOrders = async() => {
    try{
      const response = await axios.get(`http://143.244.132.221:4002/api/v1/deliveryRate`)
      setOrderData(response.data.data);
    }catch(err) {
      console.log("orderdata error",err.message);
    } 
  }

  
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/deliveryrate/add");
  };
  
  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    if (storedPage !== null) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  console.log(orderData,"orderData")
  const responseData = orderData || [];

   function handlePageChange(data) {
    const selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
    fetchOrders(selectedPage);
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);

     if (newPage === 0) {
      setCurrentPage(0);
    } else if (newPage === Math.ceil(responseData?.length / pageSize) - 1) {
      setCurrentPage(Math.ceil(responseData?.length / pageSize) - 1);
    } else {
      setCurrentPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 5));
    setCurrentPage(0);
  };


  const CustomPaginationActions = (props) => {
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };

    const handleBackButtonClick = (event) => {
      // setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

  // const gridItemView = (itemId) => (
  //   <div className="flex items-center justify-center gap-2">
  //     <h1
  //       style={{ color: "#8be78b", display: "flex" }}
  //       class="font-medium leading-tight text-xl mt-0 mb-2 "
  //     >
  //       {/* <Link style={{ marginRight: "auto" }} to="/deliveryrate/edit">
  //         <BsFillPencilFill />
  //       </Link> */}
  
  //         <Link style={{ marginRight: "auto" }} to="/deliveryrate/edit">
  //           <BsFillPencilFill  onClick={() => itemEdit(itemId)}/>
  //         </Link>
  //     </h1>
  //     <h1
  //       style={{ color: "#03c9d7", display: "flex" }}
  //       class="font-medium leading-tight text-xl mt-0 mb-2 "
  //     >
  //       <Link
  //         style={{ marginLeft: "auto", marginRight: "auto" }}
  //         to={`/deliveryrate/view/${itemId._id}`}
         
  //       >
  //         <FiEye />
  //       </Link>
  //     </h1>
  //     <h1
  //       style={{ color: "#ff5c8e", display: "flex" }}
  //       className="font-small leading-tight text-xl mt-0 mb-2 "
  //     >
       

  //       <button
  //       style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
  //       className="font-small leading-tight text-xl mt-0 mb-2 "
  //     >
  //       <DeleteRate itemId={itemId._id} />
  //     </button>
  //     </h1>
  //   </div>



  // );

  const gridItemView = (itemId) => (
    <div className="flex items-center justify-center gap-2">
      <h1
        style={{ color: "#8be78b", display: "flex" }}
        class="font-medium leading-tight text-xl mt-0 mb-2 "
      >
        {/* <Link style={{ marginRight: "auto" }} to="/deliveryrate/edit">
          <BsFillPencilFill />
        </Link> */}
 
 
        <Link style={{ marginRight: "auto" }} to="/deliveryrate/edit">
          <BsFillPencilFill onClick={() => itemEdit(itemId)} />
        </Link>
      </h1>
      <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link
          style={{ marginLeft: "auto", marginRight: "auto" }}
          to={`/deliveryrate/view/${itemId._id}`}
        >
          <FiEye />
        </Link>
      </h1>
 
 
      <button
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <DeleteRate itemId={itemId._id} />
      </button>
    </div>
  );

  const itemEdit = (itemId) => {
    const editId = itemId._id;
    console.log(editId);
    setViewData(itemId);
  };


  const currentPageData = responseData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

    console.log("current page", currentPage);
    console.log("page size", pageSize);
    console.log("total data", orderData?.length);
    console.log(Math.ceil(orderData?.length / pageSize));


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* <Header title="Delivery Rate List"  /> */}
      <div class="flex space-x-2 m-4 justify-end">
        <button type="button" onClick={() => addPage()} class="addBtn">
          New Delivery Rate
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: 'rgb(0, 60, 126)',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
       <h1 className="m-4" style={{color: "white", fontSize: '1.25rem'}}>Delivery Rate List ({orderData ? orderData.length : 0})</h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>

      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Merchant</TableCell>
              <TableCell className={classes.cellRow}>Amount 1-199</TableCell>
              <TableCell className={classes.cellRow}>Amount 200-499</TableCell>
              <TableCell className={classes.cellRow}>Amount 500</TableCell>
              <TableCell className={classes.cellRow}>Over 5Km Charge</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData?.length > 0 ? (
              currentPageData.map((item, i) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{item?.userId?.firmName}</TableCell>
                  <TableCell className={classes.cell}>{item?.chargeForOrderAmountBetween_1_199}</TableCell>
                  <TableCell className={classes.cell}>{item?.chargeForOrderAmountBetween_200_499}</TableCell>
                  <TableCell className={classes.cell}>{item?.chargeForOrderAmountGreaterThan_500}</TableCell>
                  <TableCell className={classes.cell}>{item?.perKmChargeOver_5Km}</TableCell>
                  <TableCell className={classes.cell}>{gridItemView(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {orderData?.length > 0 ? (
          <TablePagination
            count={orderData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default DeliveryRate;
