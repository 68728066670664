import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';


const Editservice = () => {
  const [formData, setFormData] = useState({
    prescription: '',
    totalDeliveryCharge: 0,
    totalGST: 0,
    address: '',
    pincode: '',
    paymentMode: '',
    houseNumber: '',
    landMark: '',
    geoLatitute: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let id = sessionStorage.getItem('id')
      const token = sessionStorage.getItem('myToken');

      const response = await fetch(`http://143.244.132.221:4002/api/v1/service/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Data successfully created
        console.log('Service created successfully');
        // Additional logic or redirect if desired
      } else {
        // Error creating data
        console.error('Failed to create service');
        // Additional error handling if desired
      }
    } catch (error) {
      console.error('Error creating service', error);
      // Additional error handling if desired
    }
  };

  return (
    <div className="add-service-page">
     
      <form className="add-service-form" onSubmit={handleSubmit}>
        <TextField
          label="Prescription"
          type="text"
          name="prescription"
          value={formData.prescription}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Total Delivery Charge"
          type="number"
          name="totalDeliveryCharge"
          value={formData.totalDeliveryCharge}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Total GST"
          type="number"
          name="totalGST"
          value={formData.totalGST}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Payment Mode"
          type="text"
          name="paymentMode"
          value={formData.paymentMode}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          type="text"
          name="address"
          value={formData.address}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Pincode"
          type="number"
          name="pincode"
          value={formData.pincode}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="House Number"
          type="number"
          name="houseNumber"
          value={formData.houseNumber}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Landmark"
          type="text"
          name="landMark"
          value={formData.landMark}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Geo Latitude"
          type="text"
          name="geoLatitute"
          value={formData.geoLatitute}
          onChange={handleInputChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <Button type="submit" variant="contained" color="primary">
          UPDATE
        </Button>
      </form>
    </div>
  );
};

export default Editservice;
