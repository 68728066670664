import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import axios from "axios";
import { useState,useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import "../view.css";


const ViewEmployee = (props) => {
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);
  
  const mainPage = () => {
  
  navigate("/employees");
};
  debugger;
  const {viewData} = useContext(StateContext)
  
  console.log(viewData);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];
    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  return (
    
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        View Employee
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="submit" style={{ width: "7%" }}
            class="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
        
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              First Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstName"
              
              value={viewData.firstName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastName"
              
              value={viewData.lastName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
        <label
        >
          Per Delivery Rate
        </label>
        <input
          type="number"
          class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="delivery"
          name="deliveryPerRate"
          value={viewData.deliveryPerRate}
        />
      </div>

      <div class="relative z-0 p-2 w-full mb-6 group">
        <label
        >
          Employee Status
        </label>
        <input
          type="text"
          class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
          id="status"
          name="status"
          value = {viewData.status}
        />
      </div>

      <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            >
              Gender
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              value={viewData.gender}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Email
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              value={viewData.email}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Mobile Number
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobilenum"
              name="mobile"
              
              value={viewData.mobile}
            />
          </div>
          
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Address
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              
              value={viewData.address}
            />
          </div>

        
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Pin Code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              
              value={viewData.pincode}
            />
          </div>


         


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"  
              
              value={viewData.country}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="state"
              
              value={viewData.state}
            />
          </div>


          

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              
              value={viewData.city}
            />
          </div>


          

                    
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Adhar Card </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharCard"
              
              value={viewData.addharCard}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              
              value={viewData.panCard}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankdetails"
              
              value={viewData.bankName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchname"
              value={viewData.branchName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accType"
              name="accType"
              value={viewData.bankAccountType}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Bank Account Number
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountno"
              
              value={viewData.accountNo}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              IFSC code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscode"
              value={viewData.ifscCode}
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Employee Type
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="empStatus"
              name="empStatus"
              value={viewData.empStatus}
            />
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Active/Inactive Status
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="activeInactive"
              name="activeInactive"
              value={viewData.ActiveInactive}
            />
          </div>
          <div>
            <label style={{paddingLeft:"12px"}}>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={viewData.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
      </div>
      </form>
    </div>
    
  );
};

export default ViewEmployee;
