import * as React from "react";
import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import "../../../pages/view.css";
import { Link } from "react-router-dom";
import StateContext from "../../../contexts/ContextProvider";
import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import axios from 'axios'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const OrderDelete = (itemId) => {
  const [open, setOpen] = React.useState(false);
  const [condition, setCondition] = React.useState(false);
  const { setValue,setOrderData } = useContext(StateContext);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const itemDelete = (itemId) => {
    const id = Object.values(itemId);
    fetch(`http://143.244.132.221:4002/api/v1/order/${id[0]}`, {
      method: "DELETE",
    })
      .then(async (response) => {
        const data = await response.json();
        console.log(data);
        setValue(data.code);
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        else{
          await axios

          .get(`http://143.244.132.221:4002/api/v1/order`)
          .then((res) => {
            let data = res.data.data
              .filter((or) => or.distributorId == sessionStorage.getItem("id"))
              .reverse()
              .map((itemId) => {
                itemId.grandTotal = getAmount(itemId);
                return itemId;
              });
            // setTDatas(data)

            setOrderData(data);
          })
          .catch((err) => {
            console.log("orderdata error", err.message);
          });
        console.log("Delete successful");
        }
      
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    handleClose();
  };
  const getAmount = (itemId) => {
    const subtotal = itemId?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b);
    const customerDiscount = itemId?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr?.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b);
    const GstLogic =
      ((subtotal - customerDiscount) *
        parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
      100;
    const DeliveryAmount = 0;
    const TotalAmount = (
      subtotal -
      customerDiscount +
      GstLogic +
      DeliveryAmount
    ).toFixed(0);
    return TotalAmount;
  };
  return (
    // <div>
    //   <button
    //     type="button"
    //     style={{ marginTop: 1, alignItems: "center" }}
    //     onClick={handleOpen}
    //   >
    //     <AiFillDelete />
    //   </button>
    //   <Modal
    //     open={open}
    //     onClose={handleClose}
    //     //hideBackdrop={true}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <Box sx={style}>
    //       <div
    //         style={{ color: "#ff5c8e", display: "flex" }}
    //         className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
    //       >
    //         <RiErrorWarningLine />
    //       </div>

    //       <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
    //         Are you sure?
    //       </h1>

    //       <h4 class="flex space-x-2 justify-center aline-center">
    //         you won't be able to revert this!
    //       </h4>

    //       <br />

    //       <div class="flex space-x-2 justify-center aline-center ml-10 pl-10">
    //         <button type="button" class="backButton" onClick={handleClose}>
    //           Cancel
    //         </button>
    //         <button
    //           type="button"
    //           class="addButton"
    //           onClick={() => itemDelete(itemId)}
    //         >
    //           Yes, Delete!
    //         </button>
    //       </div>
    //     </Box>
    //   </Modal>
    // </div>



    <div>
      <button type="button" onClick={handleOpen}>
        <AiFillDelete />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="!rounded ">
          <div
            style={{ color: "red", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure?
          </h1>

          <h4 class="flex space-x-2 justify-center aline-center">
            you won't be able to revert this!
          </h4>

          <br />
          <div className="flex space-x-2 justify-center items-center m-0 p-0">
            <button
              type="button"
              className="backButton !ml-0"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="addButton "
              onClick={() => itemDelete(itemId)}
            >
              Yes,delete it!
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default OrderDelete;
