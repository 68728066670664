import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import { BsFillPencilFill } from "react-icons/bs";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { AiOutlineFolderView } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { columnsPrepared, dataReady } from "@syncfusion/ej2-react-grids";
import { useState } from "react";
import {
  ColumnDirective,
  Edit,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
  Toolbar,
} from "@syncfusion/ej2-react-grids";

export const gridItemAction = () => (
  <div className="flex items-center justify-center gap-2">
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-xl mt-0 mb-2 "
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/onlinetransaction/view"
      >
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <button
        style={{ marginLeft: "auto", marginRight: "auto" }}
        onClick={() => deleteItem(itemsData.indexOf(id))}
      >
        <AiFillDelete />
      </button>
    </h1>
  </div>
);

const deleteItem = (id) => {
  c;
};

// const deleteItem = (id) => {
//   itemsData.map((item) => {
//     if (id === item.id) {
//       console.log(id);
//     }
//   });
// };

// const del = employees.filter(employee => id !== employee.id)
// setEmployees(del)
// console.log('res', res)

// const [datas, setDatas] = useState([itemsData]);

// const editing = { allowDeleting: true };
// const toolbarOptions = ["Delete"];

export const gridTransactionStatus = (props) => (
  <button
    type="button"
    style={{ background: props.TransactionStatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.TransactionStatus}
  </button>
);

export const gridPaymentStatus = (props) => (
  <button
    type="button"
    style={{ background: props.PaymentStatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.PaymentStatus}
  </button>
);

export const itemsGrid = [
  {
    field: "id",
    headerText: "Id",
    isPrimaryKey: true,
    textAlign: "Center",
  },
  // {
  //   field: "paymentgateway",
  //   headerText: "Payment Gateway",
  //   textAlign: "Center",
  // },
  // {
  //   field: "transactionpurpose",
  //   headerText: "Transaction Purpose",
  //   textAlign: "Center",
  // },
  {
    field: "transactionamount",
    headerText: "Transaction Amount",
    textAlign: "Center",
  },
  {
    headerText: "Transaction Status",
    template: gridTransactionStatus,
    field: "id",
    textAlign: "Center",
  },

  // {
  //   field: "transactionpaymentid",
  //   headerText: "Transaction Payment Id",
  //   textAlign: "Center",
  // },
  {
    field: "orderid",
    headerText: "Order Id",
    textAlign: "Center",
    width: "auto",
  },
  {
    field: "customername",
    headerText: "Customer Name",
    textAlign: "Center",
  },
  {
    field: "datetime",
    headerText: "Date Time",
    textAlign: "Center",
  },
  {
    headerText: "Payment Status",
    template: gridPaymentStatus,
    field: "id",
    textAlign: "Center",
  },
  // {
  //   field: "payoutdescription",
  //   headerText: "Payout Description",
  //   textAlign: "Center",
  // },
  {
    field: "action",
    headerText: "Actions",
    textAlign: "Center",
    template: gridItemAction,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  // {
  //   id: 1,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Done",
  //   TransactionStatusBg: "#8BE78B",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 2,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  {
    id: 3,
    paymentgateway: "payU",
    transactionpurpose: "Load Wallet",
    transactionamount: 500,
    TransactionStatus: "Done",
    TransactionStatusBg: "#8BE78B",
    transactionpaymentid: "HLTV",
    orderid: "LW222222222222",
    customername: "Jackz Hunter",
    datetime: "2022-05-08 19:20:00",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    payoutdescription: "",
  },
  {
    id: 4,
    paymentgateway: "payU",
    transactionpurpose: "Load Wallet",
    transactionamount: 500,
    TransactionStatus: "Done",
    TransactionStatusBg: "#8BE78B",
    transactionpaymentid: "HLTV",
    orderid: "LW222222222222",
    customername: "Jackz Hunter",
    datetime: "2022-05-08 19:20:00",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    payoutdescription: "",
  },
  // {
  //   id: 5,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  {
    id: 6,
    paymentgateway: "payU",
    transactionpurpose: "Load Wallet",
    transactionamount: 500,
    TransactionStatus: "Done",
    TransactionStatusBg: "#8BE78B",
    transactionpaymentid: "HLTV",
    orderid: "LW222222222222",
    customername: "Jackz Hunter",
    datetime: "2022-05-08 19:20:00",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    payoutdescription: "",
  },
  // {
  //   id: 7,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  {
    id: 8,
    paymentgateway: "payU",
    transactionpurpose: "Load Wallet",
    transactionamount: 500,
    TransactionStatus: "Done",
    TransactionStatusBg: "#8BE78B",
    transactionpaymentid: "HLTV",
    orderid: "LW222222222222",
    customername: "Jackz Hunter",
    datetime: "2022-05-08 19:20:00",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    payoutdescription: "",
  },
  // {
  //   id: 9,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  {
    id: 10,
    paymentgateway: "payU",
    transactionpurpose: "Load Wallet",
    transactionamount: 500,
    TransactionStatus: "Done",
    TransactionStatusBg: "#8BE78B",
    transactionpaymentid: "HLTV",
    orderid: "LW222222222222",
    customername: "Jackz Hunter",
    datetime: "2022-05-08 19:20:00",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    payoutdescription: "",
  },
  // {
  //   id: 11,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 12,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 13,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 14,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 15,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 16,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 17,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 18,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 19,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
  // {
  //   id: 20,
  //   paymentgateway: "payU",
  //   transactionpurpose: "Load Wallet",
  //   transactionamount: 500,
  //   TransactionStatus: "Pending",
  //   TransactionStatusBg: "#FF5C8E",
  //   transactionpaymentid: "HLTV",
  //   orderid: "LW222222222222",
  //   customername: "Jackz Hunter",
  //   datetime: "2022-05-08 19:20:00",
  //   PaymentStatus: "Pending",
  //   PaymentStatusBg: "#FF5C8E",
  //   payoutdescription: "",
  // },
];
