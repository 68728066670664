import { Link } from "react-router-dom";
import { AiOutlineFolderView } from "react-icons/ai";
import { AiFillDelete } from "react-icons/ai";
import ToggleSwitch from "../toogleSwitch/ToogleSwitch";
import { FiEye } from "react-icons/fi";
import DeleteOrdPayment from "./DeleteOrdPayment";

export const gridItemAction = (itemId) => (
  <div className="flex items-center justify-center gap-2">
  <h1
    style={{ color: "#03c9d7", display: "flex" }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
    <Link
      style={{ marginRight: "auto" }}
      to={`/orderpayments/view/${itemId._id}`}
    >
      <FiEye />
    </Link>
  </h1>


  <button
    style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
<DeleteOrdPayment itemId={itemId._id} />
  </button>
</div>
);



const gridorderNo = (props) => (
  <button
    type="button"
    style={{ backgroundColor: "03C9D7" }}
    className="text-black py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.orderNo.toUpperCase()}
  </button>
);
export const gridPaymentStatus = (props) => (
  <button
    type="button"
    style={{ backgroundColor: "#ff5c8e", textAlign:"center",color:"black", display:"flex", position:"relative",color:"white"}}
    className="text-black py-2 px-4 capitalize rounded-3xl text-md"
  >
    {props.paymentStatus}
  </button>
);


export const itemsGrid = [
  {
    field: "_id",
    headerText: "Id",
    isPrimaryKey: true,
    textAlign: "Center",
  },
  {
    field: "orderno",
    headerText: "Order No",
    textAlign: "Center",
    template: gridorderNo,
  },
  {
    field: "userId.firstName",
    headerText: "Beneficiary Name",
    textAlign: "Center",
  },
  {
    field: "totalPrice",
    headerText: "Payment Amount",
    textAlign: "Center",
  },
  {
    field: "createdAt",
    headerText: "Payment Date",
    textAlign: "Center",
  },
  {

    headerText: "Payment Status",

    field: "paymentStatus",
    template: gridPaymentStatus,
    textAlign: "Center",
  },
  {
    field: "paymentMode",
   
    headerText: "Payment Description",
    textAlign: "Center",
  },
  {
    field: "action",
    headerText: "Actions",
    textAlign: "Center",
    template: gridItemAction,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 2,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 3,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 4,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 5,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 6,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 7,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 8,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 9,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 10,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 11,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 12,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 13,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 14,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 15,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 16,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 17,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 18,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 19,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
  {
    id: 20,
    orderno: "F02",
    beneficiaryname: "Hotel Sunshine",
    paymentamount: 309,
    paymentdate: "2022-05-05 18:20:00",
    paymentmode: "Cash/Card/UPI",
    paymentrefno: "",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    paymentdescription: "Payment yet to be paid",
  },
];
