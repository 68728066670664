import React from "react";
import { Link, NavLink } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import logo from "../data/orbitmart-logo.png";
import NewLogo from "../data/orbit.png";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const superWhite = "white";

  const superBlue = "#003c7e";

  const lightBlue = "#4d77a5";

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2 ";
  const normalLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div
      className=" h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10"
      style={{ backgroundColor: "#003c7e" }}
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img
                src={NewLogo}
                style={{
                  alignContent: "center",
                  width: "60%",
                  height: "30%",
                  marginLeft: 45,
                }}
                alt="orbitmart Distributor"
              />
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 375,
              marginLeft: 80,
              color: "white",
              fontSize: 25,
              fontFamily: "Helvetica",
            }}
          >
            Distributor
          </span>
          {/* <span
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginTop: 375,
              marginLeft: 80,
              color: "white",
              fontSize: 25,
              fontFamily: "Helvetica",
            }}
          >
            Distributor
          </span> */}
          <div className="mt-10 ">
            {links.map((item) => (
              <div
                key={item.title}
                style={{ color: "white", fontWeight: "bold" }}
              >
                <p
                  className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                   
                    to={`/${link.name}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? lightBlue : superBlue,
                      color: isActive ? superBlue : superWhite,
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                    // target={link.name=="privacypolicy"?"_blank":""}
                  >
                    {link.icon}
                    <span className="capitalize">{link.title}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
// import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { SiShopware } from "react-icons/si";
// import { MdOutlineCancel } from "react-icons/md";
// import { TooltipComponent } from "@syncfusion/ej2-react-popups";
// import { links } from "../data/dummy";
// import logo from "../data/orbitmart-logo.png";

// import { useStateContext } from "../contexts/ContextProvider";

// const Sidebar = () => {
//   const { currentColor, activeMenu, setActiveMenu, screenSize } =
//     useStateContext();

//   const handleCloseSideBar = () => {
//     if (activeMenu !== undefined && screenSize <= 900) {
//       setActiveMenu(false);
//     }
//   };

//   const activeLink =
//     "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
//   const normalLink =
//     "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

//   return (
//     <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
//       {activeMenu && (
//         <>
//           <div className="flex">
//             <Link
//               to="/"
//               onClick={handleCloseSideBar}
//               className="items-center gap-0 ml-2 mt-2 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
//             >
//               <img
//                 src={logo}
//                 style={{ marginTop: "5px", height: "90px" }}
//                 alt="orbitmart merchant"
//               />
//               <span>Distributor</span>
//             </Link>
//             <TooltipComponent content="Menu" position="BottomCenter">
//               <button
//                 type="button"
//                 onClick={() => setActiveMenu(!activeMenu)}
//                 style={{ color: currentColor }}
//                 className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
//               >
//                 <MdOutlineCancel />
//               </button>
//             </TooltipComponent>
//           </div>
//           <div className="mt-10 ">
//             {links.map((item) => (
//               <div key={item.title}>
//                 <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
//                   {item.title}
//                 </p>
//                 {item.links.map((link) => (
//                   <NavLink
//                     to={`/${link.name}`}
//                     key={link.name}
//                     onClick={handleCloseSideBar}
//                     style={({ isActive }) => ({
//                       backgroundColor: isActive ? currentColor : "",
//                     })}
//                     className={({ isActive }) =>
//                       isActive ? activeLink : normalLink
//                     }
//                   >
//                     {link.icon}
//                     <span className="capitalize ">{link.name}</span>
//                   </NavLink>
//                 ))}
//               </div>
//             ))}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Sidebar;
