import React,{useState,useEffect, useContext} from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";

import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import "../../pages/view.css";
// import { itemsData, contextMenuItems, itemsGrid } from "../../data/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import { itemsGrid, itemsData, contextMenuItems } from "./EmployeesConfig";
import NewToggle from "../newToggle/NewToggle";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { BsFillPencilFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import DeleteEmployeeModal from "./DeleteEmployeeModal";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { Button } from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "center",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "center",
  },
}));

const Employees = () => {
  const classes = useStyles();
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];
  const [tDatas, setTDatas] = useState([""]);
  const {value , setValue} = useContext(StateContext);
  const { setViewData } = useContext(StateContext );
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);

  const addPage = () => {
    navigate("/employee/add");
  };

  const random =201;
  const fetchData = async() => {
    let id = sessionStorage.getItem("id")
    if (id !== undefined) {
      try {
        const response = await axios.get(
          `http://143.244.132.221:4002/api/v1/auth/employee/byDistributorId/${id}`
        );
        setTDatas(response.data);
        // setValue(random);
      } catch (e) {
        console.error("Error fetching", e);
      }
    } else {
      console.error("ID is undefined");
    }
    // fetch(`http://143.244.132.221:4002/api/v1/auth/employee/byDistributorId/${id}`)
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .then((data) => {
    //     setTDatas(data);
    //     console.log(tDatas);
    //   })
  };

  useEffect(() => {
    fetchData();
  }, [value]);

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    if (storedPage !== null) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  if(value === 200){
    fetchData();
    setValue(random)
  }

  const responseData = tDatas?.data || [];

  const filteredData = responseData.filter(item => item?.firstName.toLowerCase().includes(searchQuery.toLowerCase()));
  console.log(filteredData, "filteredData filteredData")
  
  const gridStatus = (tDatas) => {
    let status =tDatas.ActiveInactive
    return (
    <div
      style={{ marginLeft: 12 }}
      class="flex items-center justify-center gap-2"
    >
      <NewToggle status={status} tDatas={tDatas} />
    </div>
    );
    }
  const itemId = tDatas.data  

  const currentStatus = (itemId) => {
    return(
    <div>
      {itemId.status == "FREE" ?(<button
      type="button"
      style={{ backgroundColor:"#6de76d" }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
    >
      {itemId.status}
    </button>):(<button
      type="button"
      style={{ backgroundColor:"#ff5c8e" }}
      className="text-white py-1 px-2 capitalize rounded-2xl text-md"
    >
      {itemId.status}
    </button>)}   
      
    </div>
   );
  }
  const gridItemAction = (itemId) => (
    <div className="flex items-center justify-center gap-2">
      <h1
        style={{ color: "#8be78b", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link style={{ marginRight: "auto" }} to="/employees/edit">
          <BsFillPencilFill onClick={()=>itemEdit(itemId)}/>
        </Link>
      </h1>
      <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link
          style={{ marginLeft: "auto", marginRight: "auto" }}
          to="/employees/view"
        >
          <FiEye  onClick={() => itemView(itemId)}/>
          
        </Link>
      </h1>
      
      <button
          style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
          className="font-small leading-tight text-xl mt-0 mb-2 "
        >
          <DeleteEmployeeModal itemId={itemId._id} />
        </button>
    </div>
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);

    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(responseData?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(responseData?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };
  

  const itemEdit = (itemId) => {
    const editId = itemId._id;
    console.log(editId);
    setViewData(itemId);
  };
  
  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  console.log("current page", currentPage);
    console.log("page size", pageSize);
    console.log("total data", filteredData?.length);
    console.log(Math.ceil(filteredData?.length / pageSize));

  const itemView = (itemId) => {
   
    const viewId = itemId._id;
    console.log(viewId);
    setViewData(itemId);
  };
    return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* <Header title="Employee List" /> */}
      <div class="flex space-x-2 m-4 justify-end">
        <button type="button" onClick={() => addPage()} class="addBtn">
          New Record
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: 'rgb(0, 60, 126)',
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        }}
      >
       <h1 className="m-4" style={{color: "white", fontSize: '1.25rem'}}>Employee List ({responseData?.length || 0})</h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>

      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Id</TableCell>
              <TableCell className={classes.cellRow}>first Name</TableCell>
              <TableCell className={classes.cellRow}>last Name</TableCell>
              <TableCell className={classes.cellRow}>Email</TableCell>
              <TableCell className={classes.cellRow}>Employee Type</TableCell>
              <TableCell className={classes.cellRow}>Current Status</TableCell>
              <TableCell className={classes.cellRow}>Status</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData?.length > 0 ? (
              currentPageData.map((item, i) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{i+1}</TableCell>
                  <TableCell className={classes.cell}>{item?.firstName}</TableCell>
                  <TableCell className={classes.cell}>{item?.lastName}</TableCell>
                  <TableCell className={classes.cell}>{item?.email}</TableCell>
                  <TableCell className={classes.cell}>{item?.empStatus}</TableCell>
                  <TableCell className={classes.cell}>
                    <span style={{background: 'rgb(109, 231, 109)', color: 'white', padding: '8px 10px', borderRadius: '16px'}}>{item?.status}</span>
                  </TableCell>
                  <TableCell className={classes.cell}>{gridStatus(item)}</TableCell>
                  <TableCell className={classes.cell}>{gridItemAction(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {responseData?.length > 0 ? (
          <TablePagination
            count={responseData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Employees;
