import React from "react"
import Header  from "../components/Header";
import "./view.css"

const PrivacyPolicy = () => {


  return (
    // <div className="mx-auto w-75  px-2 crd scroll-bar">
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl ">
      <Header  title="Privacy Policy" />
      <div className="card mx-5 px-3 text-justify scroll-bar">
      {/* <h4 className="text-left pt-3">Privacy Policy</h4> */}
        <h1 className="text-danger text-center py-3">
          Growup Sindhu Technology & Solutions Pvt. Ltd.
        </h1>
        <h3 className="text-center pb-2">
          Website - <b className="text-lowercase">www.orbitmarts.com</b>
        </h3>
        <h6 className="text-left py-3">
          Guidelines, General Terms & Conditions, Policies:
        </h6>
        <p>
          <small className="py-5">
            This document is an electronic record in terms of Information
            Technology Act, 2000 and rules there under as applicable and the
            amended provisions pertaining to electronic records in various
            statutes as amended by the Information Technology Act, 2000. This
            electronic record is generated by a computer system and does not
            require any physical or digital signatures.
          </small>
          <br />
          <br />
          <small className="py-5">
            This document is published in accordance with the provisions of Rule
            3 (1) of the Information Technology (Intermediaries guidelines)
            Rules, 2011 that require publishing the rules and regulations,
            privacy policy and Terms of Use for access or usage of
            www.orbitmarts.com website{" "}
          </small>
          <br />
          <br />
          <small className="py-5">
            The domain name www.orbitmarts.com (hereinafter referred to as
            "Website") is owned by Growup Sindhu Technology & solutions Private
            Limited a company incorporated under the Companies Act, 1956 with
            its registered office at H No.3158 Shivshakti-2, Near Kudaleshwar
            Mandir, Kudal, Dist- Sindhudurg, Maharashtra-416520.India. Your use
            of the Website and services and tools are governed by the following
            terms and conditions ("Terms of Use") as applicable to the Website
            including the applicable policies which are incorporated herein by
            way of reference. If You transact on the Website, you shall be
            subject to the policies that are applicable to the Website for such
            transaction. By mere use of the Website, you shall be contracting
            with Growup Sindhu Technology & Solutions Private Limited and these
            terms and conditions including the policies constitute Your binding
            obligations. For the purpose of these Terms of Use, wherever the
            context so requires "You" or "User" shall mean any natural or legal
            person who has agreed to become a Customer on the Website by
            providing Registration Data while registering on the Website as
            Registered User using the computer systems. Orbit Mart allows the
            User to surf the Website or making purchases without registering on
            the Website. The term "We", "Us", "Our" shall mean Growup Sindhu
            Technology & Solutions Private Limited.
          </small>
          <br />
          <br />
          <small className="py-5">
            When You use any of the services provided by Us through the Website,
            including but not limited to, (e.g., Product Reviews, Merchant
            Reviews), You will be subject to the rules, guidelines, policies,
            terms, and conditions applicable to such service, and they shall be
            deemed to be incorporated into this Terms of Use and shall be
            considered as part and parcel of this Terms of Use. We reserve the
            right, at Our sole discretion, to change, modify, add or remove
            portions of these Terms of Use, at any time without any prior
            written notice to You. It is Your responsibility to review these
            Terms of Use periodically for updates / changes. Your continued use
            of the Website following the posting of changes will mean that You
            accept and agree to the revisions. As long as You comply with these
            Terms of Use, we grants You a personal, non-exclusive,
            nontransferable, limited privilege to enter and use the Website.
          </small>
          <br />
          <br />
          <small>
            <b className="py-5">
              ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
              AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF
              USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
              By impliedly or expressly accepting these Terms of Use, You also
              accept and agree to be bound by Orbit Mart Policies as amended
              from time to time.
            </b>
          </small>
        </p>
        <h6 className="text-left py-3">Customer’s Eligibility</h6>
        <p>
          <small className="py-5">
            Use of the Website is available only to persons who can form legally
            binding contracts under Indian Contract Act, 1872. Persons who are
            "incompetent to contract" within the meaning of the Indian Contract
            Act, 1872 including minors, undischarged insolvents etc. are not
            eligible to use the Website. If you are a minor i.e., under the age
            of 18 years, you shall not register as a User of the Orbit Mart
            website and shall not transact on or use the website. As a minor if
            you wish to use or transact on website, such use or transaction may
            be made by your legal guardian or parents on the Website. Orbit Mart
            reserves the right to terminate your registration and / or refuse to
            provide you with access to the Website if it is brought to Orbit
            Mart's notice or if it is discovered that you are under the age of
            18 years.
          </small>
        </p>
        <h6 className="text-left py-3">
        Your Account and Registration Obligations</h6>
        <p>  <small className="py-5">
        If You use the Website, you
        shall be responsible for maintaining the confidentiality of your Display
        Name and Password and You shall be responsible for all activities that
        occur under your Display Name and Password. You agree that if You
        provide any information that is untrue, inaccurate, not current or
        incomplete or We have reasonable grounds to suspect that such
        information is untrue, inaccurate, not current or incomplete, or not in
        accordance with this Terms of Use, we shall have the right to
        indefinitely suspend or terminate or block access of your registration
        on the Website and refuse to provide You with access to the Website.
          </small></p> 
<h6 className="text-left py-3">Communications</h6>
<p>  <small className="py-5">When You use the Website or send emails or other data,
        information or communication to us, you agree and understand that You
        are communicating with Us through electronic records and You consent to
        receive communications via electronic records from Us periodically and
        as and when required. We may communicate with you by email or by such
        other mode of communication, electronic or otherwise.</small> </p>
        <h6 className="text-left py-3"> Platform for
        Transaction and Communication </h6>
        <p>  <small className="py-5">The Website is a platform that Users
        utilize to meet and interact with one another for their transactions.
        Orbit Mart is not and cannot be a party to or control in any manner any
        transaction between the Website's Users.</small></p>
        <h6 className="text-left py-3">  Henceforward:</h6> 
        <small>
        <ol>
          <li> All
        commercial/contractual terms are offered by and agreed to between
        Customers and Merchants alone. The commercial/contractual terms include
        without limitation price, shipping costs, payment methods, payment
        terms, date, period and mode of delivery, warranties related to products
        and services and after sales services related to products and services.
        Orbit Mart does not have any control or does not determine or advise or
        in any way involve itself in the offering or acceptance of such
        commercial/contractual terms between the Customer & Merchant.</li>
        <br/>
        <li>Orbit
        Mart does not make any representation or Warranty as to specifics (such
        as quality, value, saleability, etc) of the products or services
        proposed to be sold or offered to be sold or purchased on the Website.
        Orbit Mart does not implicitly or explicitly support or endorse the sale
        or purchase of any products or services on the Website. Orbit Mart
        accepts no liability for any errors or omissions, whether on behalf of
        itself or third parties.</li> <br/>
        <li>Orbit Mart is not responsible for any
        non-performance or breach of any contract entered into between Customers
        & Merchants. Orbit Mart cannot and does not guarantee that the concerned
        Customers and/or Merchants will perform any transaction concluded on the
        Website. Orbit Mart shall not and is not required to mediate or resolve
        any dispute or disagreement between Customers & Merchants.</li> <br/>
        <li>
        Orbit Mart
        does not make any representation or warranty as to the itemspecifics
        (such as legal title, creditworthiness, identity, etc) of any of its
        Users. You are advised to independently verify the bona fides of any
        particular User that You choose to deal with on the Website and use Your
        best judgment in that behalf.</li> <br/>
        <li>Orbit Mart does not at any point of
        time during any transaction between Customers and Merchants on the
        Website come into or take possession of any of the products or services
        offered by Merchant nor does it at any point gain title to or have any
        rights or claims over the products or services offered by Merchant to
        Customer.</li> <br/>
        <li>
        At no time shall Orbit Mart hold any right, title or
        interest over the products nor shall Orbit Mart have any obligations or
        liabilities in respect of such contract entered into between Customers
        and Merchants. Orbit Mart is not responsible for unsatisfactory or
        delayed performance of services or damages or delays as a result of
        products which are out of stock, unavailable or back ordered. </li>
        <li> <br/>
        The
        Website is only a platform that can be utilized by Users to reach a
        larger base to order products or services. Orbit Mart is only providing
        a platform for communication and it is agreed that the contract for sale
        of any of the products or services shall be a strictly bipartite
        contract between the Merchant and the Customer. At no time shall Orbit
        Mart hold any right, title or interest over the products nor shall Orbit
        Mart have any obligations or liabilities in respect of such contract.
        Orbit Mart is not responsible for unsatisfactory or delayed performance
        of services or damages or delays as a result of products which are out
        of stock, unavailable or back ordered.</li> <br/>
        <li>
        You shall independently agree
        upon the manner and terms and conditions of delivery, payment, insurance
        etc. with the merchant(s) that You transact with. Disclaimer: Pricing on
        any product(s) as is reflected on the Website may due to some technical
        issue, typographical error or product information published by Merchant
        may be incorrectly reflected and in such an event Merchant may cancel
        such your order(s). </li> <br/>
        <li>
        You release and indemnify Orbit Mart and/or any
        of its officers and representatives from any cost, damage, liability or
        other consequence of any of the actions of the Users of the Website and
        specifically waive any claims that you may have in this behalf under any
        applicable law. Notwithstanding its reasonable efforts in that behalf,
        Orbit Mart cannot take responsibility or control the information
        provided by other Users which is made available on the Website. You may
        find other User's information to be offensive, harmful, inconsistent,
        inaccurate, or deceptive. Please use caution and practice safe trading
        when using the Website.<br/> Please note that there could be risks in dealing
        with underage persons or people acting under false pretence. </li></ol> </small>
        <h6 className="text-left py-3">          Charges</h6>
<p> <small className="py-5">
Registration on the Website is free for Customers. Orbit Mart does not
        charge any fee for browsing and ordering on the Website. Orbit Mart
        reserves the right to change its Fee Policy from time to time. In
        particular, Orbit Mart may at its sole discretion introduce new services
        and modify some or all of the existing services offered on the Website.
        In such an event Orbit Mart reserves the right to introduce fees for the
        new services offered or amend/introduce fees for existing services, as
        the case may be. Changes to the Fee Policy shall be posted on the
        Website and such changes shall automatically become effective
        immediately after they are posted on the Website. Unless otherwise
        stated, all fees shall be quoted in Indian Rupees. You shall be solely
        responsible for compliance of all applicable laws including those in
        India for making payments to Growup Sindhu Tchnology & Solutions Private
        Limited. </small>
</p>
        
        
<h6 className="text-left py-3">           Use of the Website</h6> 
<p> <small className="py-5">  You agree, undertake and confirm that Your
        use of Website shall be strictly governed by the following binding
        principles:</small></p>
        <small className="text-lowercase">
          <ol>
           <li> You shall not host, display, upload, modify, publish,
        transmit, update or share any information which:
        <br/>
        (a) belongs to another
        person and to which You does not have any right to; 
        <br/>
        (b) is grossly
        harmful, harassing, blasphemous, defamatory, obscene, pornographic,
        paedophilic, libellous, invasive of another's privacy, hateful, or
        racially, ethnically objectionable, disparaging, relating or encouraging
        money laundering or gambling, or otherwise unlawful in any manner
        whatever; or unlawfully threatening or unlawfully harassing including
        but not limited to "indecent representation of women" within the meaning
        of the Indecent Representation of Women (Prohibition) Act, 1986; 
        <br/>
        (c) is
        misleading in any way; <br/>
        (d) is patently offensive to the online
        community, such as sexually explicit content, or content that promotes
        obscenity, paedophilia, racism, bigotry, hatred or physical harm of any
        kind against any group or individual; <br/>
        (e) harasses or advocates
        harassment of another person;<br/>
        (f) involves the transmission of "junk
        mail", "chain letters", or unsolicited mass mailing or "spamming"; <br/>
        (g)
        promotes illegal activities or conduct that is abusive, threatening,
        obscene, defamatory or libellous;<br/>
        (h) infringes upon or violates any
        third party's rights [including, but not limited to, intellectual
        property rights, rights of privacy (including without limitation
        unauthorized disclosure of a person's name, email address, physical
        address or phone number) or rights of publicity];<br/>
        (i) promotes an
        illegal or unauthorized copy of another person's copyrighted work (see
        "Copyright complaint" below for instructions on how to lodge a complaint
        about uploaded copyrighted material), such as providing pirated computer
        programs or links to them, providing information to circumvent
        manufacture-installed copy-protect devices, or providing pirated music
        or links to pirated music files; <br/>
        (j) contains restricted or
        password-only access pages, or hidden pages or images (those not linked
        to or from another accessible page);<br/>
        (k) provides material that exploits
        people in a sexual, violent or otherwise inappropriate manner or
        solicits personal information from anyone;<br/>
        (l) provides instructional
        information about illegal activities such as making or ordering illegal
        weapons, violating someone's privacy, or providing or creating computer
        viruses;<br/>
        (m) contains video, photographs, or images of another person
        (with a minor or an adult).<br/>
        (n) tries to gain unauthorized access or
        exceeds the scope of authorized access to the Website or to profiles,
        blogs, communities, account information, bulletins, friend request, or
        other areas of the Website or solicits passwords or personal identifying
        information for commercial or unlawful purposes from other users;<br/>
        (o)
        engages in commercial activities and/or sales without Our prior written
        consent such as contests, sweepstakes, barter, advertising and pyramid
        schemes, or the ordering or selling of "virtual" products related to the
        Website. Throughout this Terms of Use, Orbit Mart's prior written
        consent means a communication coming from Orbit Mart's Legal Department,
        specifically in response to Your request, and specifically addressing
        the activity or conduct for which You seek authorization;<br/>
        (p) solicits
        gambling or engages in any gambling activity which We, in Our sole
        discretion, believes is or could be construed as being illegal; <br/>
        (q)
        interferes with another USER's use and enjoyment of the Website or any
        other individual's User and enjoyment of similar services;<br/>(r) refers to
        any website or URL that, in Our sole discretion, contains material that
        is inappropriate for the Website or any other website, contains content
        that would be prohibited or violates the letter or spirit of these Terms
        of Use.<br/>
        (s) harm minors in any way; <br/>
        (t) infringes any patent, trademark,
        copyright or other proprietary rights or third party's trade secrets or
        rights of publicity or privacy or shall not be fraudulent or involve the
        sale of counterfeit or stolen products;<br/>
        (u) violates any law for the
        time being in force;<br/>
        (v) deceives or misleads the addressee/ users about
        the origin of such messages or communicates any information which is
        grossly offensive or menacing in nature;<br/>
        (w) impersonate another person;<br/>
        (x) contains software viruses or any other computer code, files or
        programs designed to interrupt, destroy or limit the functionality of
        any computer resource; or contains any trojan horses, worms, time bombs,
        cancelbots, easter eggs or other computer programming routines that may
        damage, detrimentally interfere with, diminish value of, surreptitiously
        intercept or expropriate any system, data or personal information; <br/>
        (y)
        threatens the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign states, or public order or causes
        incitement to the commission of any cognizable offence or prevents
        investigation of any offence or is insulting any other nation. <br/>
        (z) shall
        not be false, inaccurate or misleading;<br/> (aa) shall not, directly or
        indirectly, offer, attempt to offer, trade or attempt to trade in any
        item, the dealing of which is prohibited or restricted in any manner
        under the provisions of any applicable law, rule, regulation or
        guideline for the time being in force. <br/>(ab) shall not create liability
        for Us or cause Us to lose (in whole or in part) the services of Our
        internet service provider ("ISPs") or other suppliers; 
            </li> 
            <li>You shall not
        use any "deep-link", "page-scrape", "robot", "spider" or other automatic
        device, program, algorithm or methodology, or any similar or equivalent
        manual process, to access, acquire, copy or monitor any portion of the
        Website or any Content, or in any way reproduce or circumvent the
        navigational structure or presentation of the Website or any Content, to
        obtain or attempt to obtain any materials, documents or information
        through any means not purposely made available through the Website. We
        reserve Our right to bar any such activity.</li>
        <li> You shall not attempt to
        gain unauthorized access to any portion or feature of the Website, or
        any other systems or networks connected to the Website or to any server,
        computer, network, or to any of the services offered on or through the
        Website, by hacking, password "mining" or any other illegitimate means.</li>
<li> You shall not probe, scan or test the vulnerability of the Website or
        any network connected to the Website nor breach the security or
        authentication measures on the Website or any network connected to the
        Website. You may not reverse look-up, trace or seek to trace any
        information on any other User of or visitor to Website, or any other
        customer, including any account on the Website not owned by You, to its
        source, or exploit the Website or any service or information made
        available or offered by or through the Website, in any way where the
        purpose is to reveal any information, including but not limited to
        personal identification or information, other than Your own information,
        as provided for by the Website.</li>
        <li>
        You shall not make any negative,
        denigrating or defamatory statement(s) or comment(s) about Us or the
        brand name or domain name used by Us including the terms Orbit Mart,
        orbitmart.com, or otherwise engage in any conduct or action that might
        tarnish the image or reputation, of Orbit Mart or merchant on platform
        or otherwise tarnish or dilute any Orbit Mart's trade or service marks,
        trade name and/or goodwill associated with such trade or service marks,
        trade name as may be owned or used by us. You agree that You will not
        take any action that imposes an unreasonable or disproportionately large
        load on the infrastructure of the Website or Orbit Mart's systems or
        networks, or any systems or networks connected to Orbit Mart.
        </li>
        <li>You
        agree not to use any device, software or routine to interfere or attempt
        to interfere with the proper working of the Website or any transaction
        being conducted on the Website, or with any other person's use of the
        Website.</li>
           <li>You may not forge headers or otherwise manipulate
        identifiers in order to disguise the origin of any message or
        transmittal You send to Us on or through the Website or any service
        offered on or through the Website. You may not pretend that You are, or
        that You represent, someone else, or impersonate any other individual or
        entity. </li> <li>You may not use the Website or any content for any purpose
        that is unlawful or prohibited by these Terms of Use, or to solicit the
        performance of any illegal activity or other activity which infringes
        the rights of Orbit Mart and / or others. 
          </li>
          <li>You shall at all times
        ensure full compliance with the applicable provisions of the Information
        Technology Act, 2000 and rules thereunder as applicable and as amended
        from time to time and also all applicable Domestic laws, rules and
        regulations (including the provisions of any applicable Exchange Control
        Laws or Regulations in Force) and International Laws, Foreign Exchange
        Laws, Statutes, Ordinances and Regulations (including, but not limited
        to Sales Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise,
        Custom Duty, Local Levies) regarding Your use of Our service and Your
        listing, order, solicitation of offers to order, and sale of products or
        services. You shall not engage in any transaction in an item or service,
        which is prohibited by the provisions of any applicable law including
        exchange control laws or regulations for the time being in force.
            </li>
            
            <li>Solely to enable Us to use the information You supply Us with, so that
        we are not violating any rights You might have in Your Information, you
        agree to grant Us a nonexclusive, worldwide, perpetual, irrevocable,
        royalty-free, sub-licensable (through multiple tiers) right to exercise
        the copyright, publicity, database rights or any other rights You have
        in Your Information, in any media now known or not currently known, with
        respect to Your Information. We will only use Your information in
        accordance with the Terms of Use and Privacy Policy applicable to use of
        the Website.</li>
         <li> From time to time, you shall be responsible for
        providing information relating to the products or services proposed to
        be sold by You. In this connection, you undertake that all such
        information shall be accurate in all respects. You shall not exaggerate
        or over emphasize the attributes of such products or services so as to
        mislead other Users in any manner.</li> 
        <li>You shall not engage in
        advertising to, or solicitation of, other Users of the Website to buy or
        sell any products or services, including, but not limited to, products
        or services related to that being displayed on the Website or related to
        us. You may not transmit any chain letters or unsolicited commercial or
        junk email to other Users via the Website. It shall be a violation of
        these Terms of Use to use any information obtained from the Website in
        order to harass, abuse, or harm another person, or in order to contact,
        advertise to, solicit, or sell to another person other than Us without
        Our prior explicit consent. In order to protect Our Users from such
        advertising or solicitation, we reserve the right to restrict the number
        of messages or emails which a user may send to other Users in any
        24-hour period which We deems appropriate in its sole discretion. You
        understand that We have the right at all times to disclose any
        information (including the identity of the persons providing information
        or materials on the Website) as necessary to satisfy any law, regulation
        or valid governmental request. This may include, without limitation,
        disclosure of the information in connection with investigation of
        alleged illegal activity or solicitation of illegal activity or in
        response to a lawful court order or subpoena. In addition, we can (and
        You hereby expressly authorize Us to) disclose any information about You
        to law enforcement or other government officials, as we, in Our sole
        discretion, believe necessary or appropriate in connection with the
        investigation and/or resolution of possible crimes, especially those
        that may involve personal injury.<br/> We reserve the right, but has no
        obligation, to monitor the materials posted on the Website. Orbit Mart
        shall have the right to remove or edit any content that in its sole
        discretion violates, or is alleged to violate, any applicable law or
        either the spirit or letter of these Terms of Use. Notwithstanding this
        right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS
        YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. Please be advised
        that such Content posted does not necessarily reflect Orbit Mart views.
        In no event shall Orbit Mart assume or have any responsibility or
        liability for any Content posted or for any claims, damages or losses
        resulting from use of Content and/or appearance of Content on the
        Website. You hereby represent and warrant that You have all necessary
        rights in and to all Content which You provide and all information it
        contains and that such Content shall not infringe any proprietary or
        other rights of third parties or contain any libellous, tortious, or
        otherwise unlawful information.</li><li> Your correspondence or business
        dealings with, or participation in promotions of, advertisers found on
        or through the Website, including payment and delivery of related
        products or services, and any other terms, conditions, warranties or
        representations associated with such dealings, are solely between You
        and such advertiser. We shall not be responsible or liable for any loss
        or damage of any sort incurred as the result of any such dealings or as
        the result of the presence of such advertisers on the Website.</li>  <li> It is
        possible that other users (including unauthorized users or "hackers")
        may post or transmit offensive or obscene materials on the Website and
        that You may be involuntarily exposed to such offensive and obscene
        materials. It also is possible for others to obtain personal information
        about You due to your use of the Website, and that the recipient may use
        such information to harass or injure You. We do not approve of such
        unauthorized uses, but by using the Website You acknowledge and agree
        that We are not responsible for the use of any personal information that
        You publicly disclose or share with others on the Website. Please
        carefully select the type of information that You publicly disclose or
        share with others on the Website.</li>
        <li> Orbit Mart shall have all the
        rights to take necessary action and claim damages that may occur due to
        your involvement/participation in any way on your own or through group/s
        of people, intentionally or unintentionally in DoS/DDoS (Distributed
        Denial of Services).</li> </ol></small>           
        
        <h6 className="text-left py-3">Contents Posted on Site</h6> 
       <small><p>
       All text, graphics, user
        interfaces, visual interfaces, photographs, trademarks, logos, sounds,
        music and artwork (collectively, "Content"), is a third-party user
        generated content and Orbit Mart has no control over such third-party
        user generated content as Orbit Mart is merely an intermediary for the
        purposes of this Terms of Use.<br/><br/>Except as expressly provided in these
        Terms of Use, no part of the Website and no Content may be copied,
        reproduced, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted or distributed in any way (including
        "mirroring") to any other computer, server, Website or other medium for
        publication or distribution or for any commercial enterprise, without
        Orbit Mart's express prior written consent.<br/><br/>
        You may use information on
        the products and services purposely made available on the Website for
        downloading, provided that You (1) do not remove any proprietary notice
        language in all copies of such documents, (2) use such information only
        for your personal, non-commercial informational purpose and do not copy
        or post such information on any networked computer or broadcast it in
        any media, (3) make no modifications to any such information, and (4) do
        not make any additional representations or warranties relating to such
        documents. <br/>
        <br/>
        You shall be responsible for any notes, messages, emails,
        billboard postings, photos, drawings, profiles, opinions, ideas, images,
        videos, audio files or other materials or information posted or
        transmitted to the Website (collectively, "Content"). Such Content will
        become Our property and You grant Us the worldwide, perpetual and
        transferable rights in such Content. We shall be entitled to, consistent
        with Our Privacy Policy as adopted in accordance with applicable law,
        use the Content or any of its elements for any type of use forever,
        including but not limited to promotional and advertising purposes and in
        any media whether now known or hereafter devised, including the creation
        of derivative works that may include the Content You provide. You agree
        that any Content You post may be used by us, consistent with Our Privacy
        Policy and Rules of Conduct on Site as mentioned herein, and You are not
        entitled to any payment or other compensation for such use.


        <h6 className="text-left py-3">Privacy </h6>
        We
        view protection of Your privacy as a very important principle. We
        understand clearly that You and Your Personal Information is one of Our
        most important assets. We store and process Your Information including
        any sensitive financial information collected (as defined under the
        Information Technology Act, 2000), if any, on computers that may be
        protected by physical as well as reasonable technological security
        measures and procedures in accordance with Information Technology Act
        2000 and Rules there under. If You object to Your Information being
        transferred or used in this way, please do not use Website.
        <br/> We and our
        affiliates will share / sell / transfer / license / covey some or all of
        your personal information with another business entity should we (or our
        assets) plan to merge with or are acquired by that business entity, or
        re-organization, amalgamation, restructuring of business or for any
        other reason whatsoever. Should such a transaction or situation occur,
        the other business entity or the new combined entity will be required to
        follow the privacy policy with respect to your personal information.
        Once you provide your information to us, you provide such information to
        us and our affiliate and we and our affiliate may use such information
        to provide you various services with respect to your transaction whether
        such transaction is conducted on www.orbitmarts.com website.<br/>
        <h6 className="text-left py-3"> Disclaimer of Warranties and Liability</h6>
 
         This Website, all the materials and products
        (including but not limited to software) and services, included on or
        otherwise made available to You through this site are provided on "as
        is" and "as available" basis without any representation or warranties,
        express or implied except otherwise specified in writing. Without
        prejudice to the forgoing paragraph, Orbit Mart does not warrant that: <br/>
        
        • This Website will be constantly available, or available at all; or <br/>• The
        information on this Website is complete, true, accurate or
        non-misleading.<br/> Orbit Mart will not be liable to You in any way or in
        relation to the Contents of, or use of, or otherwise in connection with,
        the Website. Orbit Mart does not warrant that this site; information,
        Content, materials, product (including software) or services included on
        or otherwise made available to You through the Website; their servers;
        or electronic communication sent from Us are free of viruses or other
        harmful components.<br/> You will be required to enter a valid phone number
        while placing an order on the Website. By registering Your phone number
        with us, you consent to be contacted by Us via phone calls and/or SMS
        notifications, in case of any order or delivery related updates. We will
        not use your personal information to initiate any promotional phone
        calls or SMS.<br/>
        <h6 className="text-left py-3">Services </h6>
        <b>Payment</b><br/><br/> While availing any of the payment
        method/s available on the Website, we will not be responsible or assume
        any liability, whatsoever in respect of any loss or damage arising
        directly or indirectly to You due to:<br/> Lack of authorization for any
        transaction/s, or <br/>Exceeding the pre-set limit mutually agreed by You and
        between "Bank/s", or <br/>Any payment issues arising out of the transaction,
        or <br/>Decline of transaction for any other reason/s All payments made
        against the purchases/services on Website by you shall be compulsorily
        in Indian Rupees acceptable in the Republic of India. Website will not
        facilitate transaction with respect to any other form of currency with
        respect to the purchases made on Website. <br/>Before shipping / delivering
        your order to you, Merchant may request you to provide supporting
        documents (including but not limited to Govt. issued ID and address
        proof) to establish the ownership of the payment instrument used by you
        for your purchase. This is done in the interest of providing a safe
        online shopping environment to Our Users. <br/><br/><b className="pt-3 pb-3">Further:</b> <br/><br/>1. Transactions,
        Transaction Price and all commercial terms such as Delivery, Dispatch of
        products and/or services are as per principal-to-principal bipartite
        contractual obligations between Customer and Merchant and payment
        facility is merely used by the Customer and Merchant to facilitate the
        completion of the Transaction. Use of the payment facility shall not
        render Orbit Mart liable or responsible for the non-delivery,
        non-receipt, nonpayment, damage, breach of representations and
        warranties, non-provision of after sales or warranty services or fraud
        as regards the products and /or services listed on Orbit Mart's Website.<br/>
        2. You have specifically authorized Orbit Mart or its service providers
        to collect, process, facilitate and remit payments and /or the
        Transaction Price electronically or through Cash on Delivery to and from
        other Users in respect of transactions through Payment Facility. Your
        relationship with Orbit Mart is on a principal-to-principal basis and by
        accepting these Terms of Use you agree that Orbit Mart is an independent
        contractor for all purposes, and does not have control of or liability
        for the products or services that are listed on Orbit Mart's Website
        that are paid for by using the Payment Facility. Orbit Mart does not
        guarantee the identity of any User nor does it ensure that a customer or
        a Merchant will complete a transaction. <br/>3. You understand, accept and
        agree that the payment facility provided by Orbit Mart is neither a
        banking nor financial service but is merely a facilitator providing an
        electronic, automated online electronic payment, receiving payment
        through Cash on Delivery, collection and remittance facility for the
        Transactions on the Orbit Mart Website using the existing authorized
        banking infrastructure and Credit Card payment gateway networks.
        Further, by providing Payment Facility, Orbit Mart is neither acting as
        trustees nor acting in a fiduciary capacity with respect to the
        Transaction or the Transaction Price. <br/><br/><b>Payment Facility for Customers:</b><br/><br/> •
        You, as a Customer, understand that upon initiating a Transaction You
        are entering into a legally binding and enforceable contract with the
        Merchant to order the products and /or services from the Merchant using
        the Payment Facility, and You shall pay the Transaction Price through
        Your Issuing Bank to the Merchant using Payment Facility.<br/><br/> • You, as a
        Customer, may agree with the Merchant through electronic communication
        and electronic records and using the automated features as may be
        provided by Payment Facility on any extension / increase in the Dispatch
        and/or Delivery time and the Transaction shall stand amended to such
        extent. Any such extension / increase of Dispatch / Delivery time or
        subsequent novation / variation of the Transaction should be in
        compliance with Payment Facility Rules and Policies. <br/><br/>• You, as a
        Customer, shall electronically notify Payment Facility using the
        appropriate Orbit Mart Website features immediately upon Delivery or
        non-Delivery within the time period as provided in Policies. Non
        notification by You of Delivery or non-Delivery within the time period
        specified in the Policies shall be construed as a deemed Delivery in
        respect of that Transaction. In case of Cash on Delivery transactions,
        Customer is not required to confirm the receipt of products or services.<br/><br/>
        • You, as a Customer, shall be entitled to claim a refund of the
        Transaction Price (as Your sole and exclusive remedy) in case You do not
        receive the Delivery within the time period agreed in the Transaction or
        within the time period as provided in the Policies, whichever is
        earlier. In case you do not raise a refund claim using Website features
        within the stipulated time than this would make You ineligible for a
        refund.<br/><br/> • You, as a customer, understand that the Payment Facility may
        not be available in full or in part for certain category of products
        and/or services and/or Transactions as mentioned in the Policies and
        hence You may not be entitled to a refund in respect of the Transactions
        for those products and /or services <br/><br/>• Except for Cash on Delivery
        transaction, refund, if any, shall be made at the same Issuing Bank from
        where Transaction Price was received. <br/><br/>• For Cash on Delivery
        transactions, refunds, if any, will be made by us in favour of the
        Customer (As per registration details provided by the Customer)<br/><br/> • Refund
        shall be made in Indian Rupees only and shall be equivalent to the
        Transaction Price received in Indian Rupees. <br/><br/>• For electronics payments,
        refund shall be made through payment facility using NEFT / RTGS or any
        other online banking / electronic funds transfer system approved by
        Reserve Bank India (RBI). <br/><br/>• Refund shall be conditional and shall be
        with recourse available to Orbit Mart in case of any misuse by Customer.<br/><br/>
        • Refund shall be subject to Customer complying with Policies.<br/><br/> 4. Orbit
        Mart reserves the right to impose limits on the number of Transactions
        or Transaction Price which Orbit Mart may receive from on an individual
        Valid Credit/Debit/ Cash Card / Valid Bank Account/ and such other
        infrastructure or any other financial instrument directly or indirectly
        through payment aggregator or through any such facility authorized by
        Reserve Bank of India to provide enabling support facility for
        collection and remittance of payment or by an individual Customer during
        any time period, and reserves the right to refuse to process
        Transactions exceeding such limit. <br/><br/> 5. Orbit Mart reserves the right to
        refuse to process Transactions by Customers with a prior history of
        questionable charges including without limitation breach of any
        agreements by Customer with Orbit Mart or breach/violation of any law or
        any charges imposed by Issuing Bank or breach of any policy.<br/><br/>  6. Orbit
        Mart may do such checks as it deems fit before approving the receipt
        of/Customer commitment to pay (for Cash on Delivery transactions)
        Transaction Price from the Customer for security or other reasons at the
        discretion of Orbit Mart. As a result of such check if Orbit Mart is not
        satisfied with the creditability of the Customer or genuineness of the
        Transaction / Transaction Price, it will have the right to reject the
        receipt of / Customer commitment to pay Transaction Price.<br/><br/>  7. Orbit Mart
        may delay notifying the payment confirmation i.e., informing Merchant to
        Dispatch, if Orbit Mart deems suspicious or for Customers conducting
        high transaction volumes to ensure safety of the Transaction and
        Transaction Price. In addition, Orbit Mart may hold Transaction Price
        and Orbit Mart may not inform Merchant to Dispatch or remit Transaction
        Price to law enforcement officials (instead of refunding the same to
        Customer) at the request of law enforcement officials or in the event
        the Customer is engaged in any form of illegal activity. <br/><br/> 8. The Customer
        and Merchant acknowledge that Orbit Mart will not be liable for any
        damages, interests or claims etc. resulting from not processing a
        Transaction/Transaction Price or any delay in processing a
        Transaction/Transaction Price which is beyond control of Orbit Mart.
        Compliance with Laws: <br/><br/> 9. Customer and Merchant shall comply with all the
        applicable laws (including without limitation Foreign Exchange
        Management Act, 1999 and the rules made and notifications issued there
        under and the Exchange Control Manual as may be issued by Reserve Bank
        of India from time to time, Customs Act, Information and Technology Act,
        2000 as amended by the Information Technology (Amendment) Act 2008,
        Prevention of Money Laundering Act, 2002 and the rules made there under,
        Foreign Contribution Regulation Act, 1976 and the rules made there
        under, Income Tax Act, 1961 and the rules made there under, Export
        Import Policy of government of India) applicable to them respectively
        for using Payment Facility and Orbit Mart Website.  <br/><br/><b>Customer's
        arrangement with Issuing Bank: </b><br/><br/> 10. All Valid Credit / Debit/ Cash Card/
        and other payment instruments are processed using a Credit Card payment
        gateway or appropriate payment system infrastructure and the same will
        also be governed by the terms and conditions agreed to between the
        Customer and the respective Issuing Bank and payment instrument issuing
        company.<br/><br/>  11. All Online Bank Transfers from Valid Bank Accounts are
        processed using the gateway provided by the respective Issuing Bank
        which support Payment Facility to provide these services to the Users.
        All such Online Bank Transfers on Payment Facility are also governed by
        the terms and conditions agreed to between Customer and the respective
        Issuing Bank. <br/><br/><b>Wallet</b><br/><br/> The Wallet ("Wallet") is a pre-paid payment
        instrument which is associated with your website account. In which you
        maintain a balance money and redeem it at your convenience. Please note
        that our Distributor/ Franchiser will be the issuer of wallet and he/she
        shall be responsible for redeeming the money lying to your credit of
        your wallet account and our company does not assume any risk, liability
        or responsibility with respect to wallet money.<br/><br/> Distributor/Franchiser
        will not pay any interest on the amount maintained by you in your
        distributor/ Franchiser specific Wallet. <br/><br/><b>Refill of Wallet:</b> Your Wallet
        can be refilled, using any of the pre-paid payment options only. You
        will be able to recharge your Wallet up to an amount of ₹10,000 in one
        instance. There is no need to maintain a minimum balance.<br/><br/><b> Refund of
        Wallet Amount: </b>Your Wallet comprises of three types of balances - Store
        credit (which is a credit granted by Us typically in lieu of a cancelled
        order), Topped-up balance (which is a prepaid amount that You added to
        the Wallet to pay for future orders on the Website) and Promotional
        balance (which is credit granted by Us purely for promotional or
        goodwill purposes). Topped-up balance (prepaid amount) as well as the
        Promotional balance added to the Wallet will be non-refundable while
        Store credit will continue to be entirely refundable.<br/><br/><b>Limitation of
        Liability:</b>  In no event will our company/Orbit Mart will be liable for
        any money lying to your credit in wallet, any special, incidental,
        indirect or consequential damages or losses of any kind, arising from
        the use of Wallet or association with the Wallet.<br/><br/><b>Order Cancellation or
        Replacement</b> <br/><br/> Customer will cancel his order through our system/ mobile
        app. If the product is damage or have any defect then customers will be
        claimed to replaced it. The whole responsibility of replacement is on
        our registered merchant.  <br/><br/> If Orbit Mart has suspicion or knowledge, that
        any of its Customer and Merchant are involved in any activity that is
        intended to provide claims or information that is false, misleading or
        not genuine, then Orbit Mart may while reserving its rights to initiate
        civil and/or criminal proceedings against member may also at its sole
        discretion suspend, block, restrict, cancel the Display Name of such
        Customer and Merchant and /or disqualify that user and any related users
        from availing protection through this program. <br/><br/>  Orbit Mart reserves its
        right to initiate civil and/or criminal proceedings against a user who,
        files an invalid and/or false claims or provides false, incomplete, or
        misleading information. In addition to the legal proceedings as
        aforesaid, Orbit Mart may at its sole discretion suspend, block,
        restrict, cancel the Display Name [and its related Display Names] of
        such user and/or disqualify that user and any related users from
        availing protection through this program. Any person who, knowingly and
        with intent to injure, defraud or deceive, files a Fraudulent Complaint
        containing false, incomplete, or misleading information may be guilty of
        a criminal offence and will be prosecuted to the fullest extent of the
        law. <br/><br/><b>Intellectual Property Rights: </b><br/><br/>Intellectual Property Rights for the
        purpose of this Terms of Use shall always mean and include copyrights
        whether registered or not, patents including rights of filing patents,
        trademarks, trade names, trade dresses, house marks, collective marks,
        associate marks and the right to register them, designs both industrial
        and layout, geographical indicators, moral rights, broadcasting rights,
        displaying rights, distribution rights, selling rights, abridged rights,
        translating rights, reproducing rights, performing rights, communicating
        rights, adapting rights, circulating rights, protected rights, joint
        rights, reciprocating rights, infringement rights. All those
        Intellectual Property rights arising as a result of domain names,
        internet or any other right available under applicable law shall vest in
        the domain of our Company/Orbit Mart as the owner of such domain name.
        The Parties hereto agree and confirm that no part of any Intellectual
        Property rights mentioned hereinabove is transferred in the name of User
        and any intellectual property rights arising as a result of these
        presents shall also be in the absolute ownership, possession and Our
        control or control of its licensors, as the case may be.<br/><br/> <b>Indemnity</b><br/><br/> You
        shall indemnify and hold harmless Orbit Mart, its owner, licensee,
        affiliates, subsidiaries, group companies (as applicable) and their
        respective officers, directors, agents, and employees, from any claim or
        demand, or actions including reasonable attorneys' fees, made by any
        third party or penalty imposed due to or arising out of Your breach of
        this Terms of Use, privacy Policy and other Policies, or Your violation
        of any law, rules or regulations or the rights (including infringement
        of intellectual property rights) of a third party. <br/><br/><b>Applicable Law</b><br/><br/> Terms
        of Use shall be governed by and interpreted and construed in accordance
        with the laws of India. The place of jurisdiction shall be exclusively
        in Sindhudurg District.<br/><br/> <b>Jurisdictional Issues/Sale</b> <br/><br/>in India Only Unless
        otherwise specified, the material on the Website is presented solely for
        the purpose of sale in India. Orbit Mart make no representation that
        materials in the Website are appropriate or available for use in other
        locations/Countries other than India. Those who choose to access this
        site from other locations/Countries other than India do so on their own
        initiative and Orbit Mart is not responsible for supply of
        products/refund for the products ordered from other locations/Countries
        other than India, compliance with local laws, if and to the extent local
        laws are applicable<br/><br/> <b>Trademark, Copyright and Restriction </b><br/><br/>This site is
        controlled and operated by Growup Sindhu Technology & Solutions Pvt Ltd
        and products are sold by respective Merchants. All material on this
        site, including images, illustrations, audio clips, and video clips, are
        protected by copyrights, trademarks, and other intellectual property
        rights. Material on Website is solely for Your personal, noncommercial
        use. You must not copy, reproduce, republish, upload, post, transmit or
        distribute such material in any way, including by email or other
        electronic means and whether directly or indirectly and You must not
        assist any other person to do so. Without the prior written consent of
        the owner, modification of the materials, use of the materials on any
        other website or networked computer environment or use of the materials
        for any purpose other than personal, non-commercial use is a violation
        of the copyrights, trademarks and other proprietary rights, and is
        prohibited. Any use for which You receive any remuneration, whether in
        money or otherwise, is a commercial use for the purposes of this clause.<br/><br/>
        <b>Trademark complaint </b><br/><br/>Orbit Mart respects the intellectual property of
        others. In case You feel that Your Trademark has been infringed, you can
        write to Orbit Mart on www.orbitmarts.com.<br/><br/><b>Product Description</b> <br/><br/> Orbit
        Mart we do not warrant that Product description or other content of this
        Website is accurate, complete, reliable, current, or error-free and
        assumes no liability in this regard.<br/><br/><b>Limitation of Liability</b> <br/><br/> IN NO EVENT
        SHALL ORBIT MART BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR
        CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE,
        EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH
        DAMAGES.<br/><br/> <b>Contact Us</b> <br/><br/>Please Contact Us for any questions or comments
        (including all inquiries unrelated to copyright infringement) regarding
        this Website.<br/><br/> <b>Grievance officer</b> <br/><br/>In accordance with Information
        Technology Act 2000 and rules made there under, the name and contact
        details of the Grievance Officer are provided below:<br/><br/> <b>Director :- </b><br/><br/>Mr.
        Rajaram Gurunath Samant <br/><br/>Mrs. Manjiri Deepak Dabholkar<br/><br/> <b>Growup Sindhu
        Technology & Solutions Private Limited </b><br/><br/>H No.3158 Shivshakti-2, Near
        Kudaleshwar Mandir, Kudal, <br/>Dist- Sindhudurg, Maharashtra- 416520.India.<br/><br/>
        <b>Phone:</b> +91 -8109099709 <br/><b>Email:</b> growupsindhu@gmail.com <br/><br/><b>POLICIES</b><br/><br/><b>Profanity
        Policy</b><br/><br/> Orbit Mart prohibits the use of language that is racist, hateful,
        sexual or obscene in nature in a public area.<br/><br/> This policy extends to
        text within listings, on Merchant pages and all other areas of the site
        that another User may view. If the profane words are part of a title for
        the item being sold, we allow Merchants to 'blur' out the bulk of the
        offending word with asterisks (i.e., s*** or f***). <br/><br/>Please report any
        violations of this policy to the correct area for review: <br/><br/>Report
        offensive Display Names<br/><br/> Report offensive language in a listing or
        otherwise<br/><br/> If a feedback comment; or any communication made between Users
        on the Website; or email communication between Users in relation to
        transactions conducted on Website contain profanity, please review Our
        feedback removal policy and submit a request for action/removal.<br/><br/>
        Disciplinary action may result in the indefinite suspension of a user’s
        account, temporary suspension, or a formal warning.<br/><br/> Orbit Mart will
        consider the circumstances of an alleged policy violation and the user's
        trading records before taking action.<br/><br/> Violations of this policy may
        result in a range of actions, including: <br/><br/>Limits placed on account
        privileges;<br/><br/> Loss of special status; <br/><br/>Account suspension.<br/><br/><b> Replacement
        Guarantee</b><br/><br/> The Replacement Guarantee seeks to assist Customers who have
        been defrauded by registered Merchants on the Website. If at the time of
        delivery of the product/s, if any defect is found, then the Customer can
        ask for replacement of the product/s from the Merchant.<br/><br/> If Orbit Mart
        has suspicion or knowledge, that any of its Customers and Merchants are
        involved in any activity that is intended to provide claims or
        information that is false, misleading or not genuine, then Orbit Mart
        may while reserving its rights to initiate civil and/or criminal
        proceedings against User may also at its sole discretion suspend, block,
        restrict, cancel the Display Name of such Customer and Merchant and /or
        disqualify that User and any related Users from availing protection
        through this program.<br/><br/> Orbit Mart reserves its right to initiate civil
        and/or criminal proceedings against a User who, files invalid and/or
        false claims or provides false, incomplete, or misleading information.
        In addition to the legal proceedings as aforesaid, Orbit Mart may at its
        sole discretion suspend, block, restrict, cancel the Display Name [and
        its related Display Names] of such User and/or disqualify that User and
        any related Users from availing protection through this program. Any
        person who, knowingly and with intent to injure, defraud or deceive,
        files a Fraudulent Complaint containing false, incomplete, or misleading
        information may be guilty of a criminal offence and will be prosecuted
        to the fullest extent of the law. <br/><br/><b>Returns Policy</b><br/><br/>  Definition:'Return' is
        defined as the action of giving back the item ordered by the Customer to
        the Merchant on the Orbit Mart website. Following situations may arise:<br/><br/>
        Item was defective <br/><br/>Item was damaged during the Shipping <br/><br/>Products was /
        were missing<br/><br/> Wrong item was sent by the Merchant.<br/><br/> Return could also
        result in refund of money in most of the cases.<br/><br/><b> Points to be noted:</b><br/><br/>
        Merchant can always accept the return irrespective of the policy.<br/><br/> If
        Merchant disagrees a return request, Customer can file a dispute under
        the Customer Protection Program. <br/><br/>We encourage the Customer to review the
        listing before making the Purchase decision. In case Customer orders a
        wrong item, Customer shall not be entitled to any return/refund. <br/><br/>On
        certain select days as specified by Orbit Mart (such as ‘Today’s Best
        Deals’) separate policies may be applicable. <br/><br/>In the event the Merchant
        accepts the return request raised by the Customer, Customer will have to
        return the product and then the refund shall be credited/give to the
        Customer account.<br/><br/> Shipping cost for returning the product shall be borne
        and incurred by the Merchant.<br/><br/> <b>Replacement </b><br/><br/>Definition: Replacement is the
        action or process of replacing something in place of another. A Customer
        can request for replacement whenever he is not happy with the item,
        reason being Damaged in shipping, Defective item, wrong item shipped and
        the like.<br/><br/> <b>Points to be noted: </b><br/><br/>Merchant can always accept the return
        irrespective of the policy. <br/><br/>If Merchant disagrees for a return request,
        Customer can file a dispute under Customer Protection Program.<br/><br/> 1. An
        intimation shall be provided to Merchant seeking either "approval" or
        "rejection" of the replacement request. <br/><br/>2. In case the Merchant accepts
        the replacement request, Customer shall be required to return the
        product to the Merchant and only after return of the product, Merchant
        shall be obliged to provide the replacement product to the Merchant. <br/><br/>3.
        Incase Merchant rejects the replacement request, Customer can choose to
        raise a dispute by writing to www.orbit marts.com. <br/><br/>4. In case the
        Merchant doesn't have the product at all, Merchant can provide the
        refund to the Customer and Customer shall be obligated to accept the
        refund in lieu of replacement. All the product parameters shall be
        required to be complied with in cases of replacement. <br/><br/>5. All shipping
        and other replacement charges shall be borne and incurred by the
        Merchant. <br/><br/><b>Disputes (Resolutions) Policy</b> <br/><br/><b>Overview</b> <br/><br/>Generally, transactions
        are conducted smoothly on Orbit Mart. However, there may be some cases
        where both the Customer and Merchant may face issues. At Orbit Mart, we
        have a Dispute Resolution process in order to resolve disputes between
        Customer and Merchant.<br/><br/> <b>What is a 'dispute'?</b> <br/><br/>A 'Dispute' can be defined
        as a disagreement between a Customer and a Merchant in connection with a
        transaction on the Website.<br/><br/> <b>How does a 'dispute' occur in the
        Marketplace? </b><br/><br/>Disputes are filed as a result of a disagreement between
        the Customer and the Merchant. Disputes arise out of an issue that is
        raised by either party not being completely satisfied with the
        resolution of their complaint/issue. <br/><br/>It is important that before a
        Customer/Merchant raises a dispute, they should attempt to solve the
        issue. Please note that whenever Customer raises a dispute, the
        Merchant's payment for that order is put on hold immediately until the
        issue is resolved.<br/><br/><b> What are the various types of 'disputes'?</b> <br/><br/>Following
        are the indicative examples of potential disputes:<br/><br/> 1. Wrong item
        received <br/>2. Item/Product Not as described<br/> 3. Damaged or Seal broken on
        Product <br/>4. Merchant Description/Specification Wrong <br/>5. Defective
        (Functional issues) <br/><br/>In case the Merchant rejects the return request of
        the Customer, and Customer raises a dispute, then our registered
        Distributor/Franchiser will try to mediate and resolve the dispute
        between both the parties. If the dispute is resolved in favour of the
        Customer, a refund is provided once the product is returned to the
        Merchant. If the dispute is settled in favour of the Merchant, Customer
        is entitled to any refund.<br/><br/> <h6>Customer Protection Policies</h6><br/> In case of a
        dispute where the Merchant is unable to provide a refund or a
        replacement, Orbit Mart will actively work towards reaching a
        resolution. <br/><br/>The Customer Protection Program covers Customer who are
        unable to successfully resolve their dispute with the Merchant or are
        not satisfied the resolution provided by the Merchant. <br/><br/>The Customer can
        write to www.orbitmart.com if the issue with the Merchant is not
        resolved. Orbit Mart's Customer Support team will look into the case to
        check for possible fraud and if the Customer has been
        blacklisted/blocked from making purchases on the Website. Only after
        verifying these facts, a dispute can be registered.<br/><br/> In due course of
        resolution, Orbit Mart's Customer Support Team will facilitate a
        conference call including the Merchant and Customer.<br/><br/> When a dispute has
        been raised, Orbit Mart may provide both the parties access to each
        other Display Names, contact details including email addresses and other
        details pertaining to the dispute.Customer and Merchant are subject to
        final consent from Orbit Mart for settling the dispute. <br/><br/> Customer
        Eligibility and Restrictions<br/><br/>  • Only the Customer who have purchased the
        product on Orbit Mart are eligible for the Customer Protection Program.<br/><br/> 
        • Any damage or loss to the product after delivery will not be covered
        under this policy and will completely be the Customer responsibility.
        Customer should refuse to accept delivery if the item is damaged. <br/><br/> • To
        be able to take advantage of the Customer Protection Policies, Customer
        should first contact the Merchant and attempt to resolve the issue. If
        the Customer doesn't hear from the Merchant or is unable to resolve the
        issue with the Merchant even after contact, a dispute can be raised with
        Orbit Mart by writing an email to www.orbitmart.com<br/><br/>  • Fraudulent charges
        and claims are not covered under Customer Protection Policies.<br/><br/>  • If the
        Customer has already initiated chargeback through the credit card
        issuing bank, it will not be covered under Customer Protection Policies,
        though in such cases a Merchant can file a claim through the Merchant
        Protection Policies.<br/><br/>  • Blacklisted and Blocked Customer are not covered
        by the Customer Protection Policies.<br/><br/>  • Through the Customer Protection
        program, Orbit Mart does not provide any guarantee/warranty to Customer
        for products sold on Orbit Mart against technical/manufacturing defects.<br/><br/> 
        • Orbit Mart shall at no point be responsible for any direct or indirect
        losses, expenses, costs of any nature whatsoever that may be incurred by
        any Customer/ Merchant. <br/><br/> • Claims of the nature of 'Customer remorse'
        (i.e., instances where products are bought by the Customer by mistake or
        where the Customer chooses to change his/her mind with regard to the
        product purchased by him) will not be entertained through this policy. <br/><br/> •
        Orbit Mart reserves its right to initiate civil and/or criminal
        proceedings against a User who, files an invalid and/or false claims or
        provides false, incomplete, or misleading information. In addition to
        the legal proceedings as aforesaid, Orbit Mart may at its sole
        discretion suspend, block, restrict, cancel the Display Name [and its
        related Display Names] of such User and/or disqualify that user and any
        related users from availing protection through this policy.<br/><br/>  • Decisions
        made by Orbit Mart under the Customer Protection Policy shall be final
        and binding on its Users.<br/><br/>  • Orbit Mart reserves the right to modify /
        discontinue Customer Protection Policy without any prior notice period
        to its Users. <br/><br/> <b>Disputes via Chargeback</b><br/><br/> <b>Whenever a chargeback (CB) comes
        from a payment gateway/bank, following situations may arise: </b><br/><br/>1. Item not
        received CB - Customer hasn't received the item. Refund will be created
        in accordance with the dispute policies <br/><br/>2. Unauthorized CB - Customer
        hasn't made this particular transaction. Refund will be created in
        accordance with the dispute policies.<br/><br/> Merchant expressly agrees that
        issuing the correct and complete invoice is the sole and primary
        responsibility of the Merchant. Furthermore, Merchant shall ensure that
        invoices state "Powered by Orbit Mart" and failing to do so Merchant
        will be liable for chargebacks (as applicable). <br/><br/>3. Item/Product not as
        described - meaning item/product is not what Customer expected.Dispute
        will be decided in accordance with the dispute policies. <br/><br/><b>Email Abuse &
        Threat Policy</b><br/><br/> Private communication, including email correspondence, is
        not regulated by Orbit Mart. Orbit Mart encourages its Users to be
        professional, courteous and respectful when communicating by email.<br/>
        <br/>However, Orbit Mart will investigate and can take action on certain
        types of unwanted emails that violate Orbit Mart policies. 
        <br/><br/><b> Such instances:</b> <br/><br/> Threats of Bodily Harm – Orbit Mart does not permit Users to
        send explicit threats of bodily harm.<br/> <br/>Misuse of Orbit Mart System –
        Orbit Mart allows Users to facilitate transactions through the Orbit
        Mart system, but will investigate any misuse of this service.<br/> <br/>Spoof
        (Fake) email – Orbit Mart will never ask you to provide sensitive
        information through email. In case you receive any spoof (fake) email,
        you are requested to report the same to Us through 'Contact Us' tab.<br/>
        Spam (Unsolicited Commercial email) – Orbit Mart's spam policy applies
        only to unsolicited commercial messages sent by Orbit Mart Users. Orbit
        Mart Users are not allowed to send spam messages to other Users.<br/> <br/>Orbit
        Mart policy prohibits user-to-user threats of physical harm via any
        method including, phone, email and on Our public message boards.<br/><br/>
        Violations of this policy may result in a range of actions, including:<br/><br/>
        Limits on account privileges<br/> <br/>Account suspension <br/><br/>Cancellation of listings<br/><br/>
        Loss of special status <br/><br/>Other Businesses
        <br/> <br/>Orbit Mart does not take
        responsibility or liability for the actions, products, content and
        services on the Website, which are linked to Affiliates and / or third-
        party websites using Website's APIs or otherwise. In addition, the
        Website may provide links to the third- party websites of Our affiliated
        companies and certain other businesses for which, Orbit Mart assumes no
        responsibility for examining or evaluating the products and services
        offered by them. Orbit Mart do not warrant the offerings of, any of
        these businesses or individuals or the content of such third- party
        website(s). Orbit Mart does not endorse, in any way, any third- party
        website(s) or content thereof. <br/><br/>Orbit Mart does not and cannot verify
        that Merchant have the right or ability to sell or distribute their
        listed products. However, Orbit Mart is committed to removing infringing
        or unlicensed products once an authorized representative of the rights
        owner properly reports them to Orbit Mart.
        </p></small>     
      </div>
    </div>
  );
};

export default PrivacyPolicy;
