import React, { useState, useEffect, useContext } from "react";
import { itemsGrid, itemsData, contextMenuItems } from "./AppUsersConfig";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";


// import { itemsData, contextMenuItems, itemsGrid } from "../../data/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";

const AppUsers = () => {
  const distId = sessionStorage.getItem("id");
  // const [orderData, setOrderData] = useState([]);
  const {orderData,setOrderData}=useContext(StateContext)
  function fetchOrders() {
    axios
      // .get("http://143.244.132.221:4002/api/v1/order/user/62ea12a97c351748a882582c")
      .get(`http://143.244.132.221:4002/api/v1/order/`)
      .then((res) => {
        console.log("hello orderdata", res.data.data);
        setOrderData(res.data.data.reverse());

        // let data = res.data.data
        // .filter((or) => or.distributorId == distId)
        // .reverse()
        
      // console.log("hello orderdata", res.data.data);
      // setOrderData(data);
      })
      .catch((err) => {
        console.log("orderdata error",err.message);
      });
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/manageItem/add");
  };


  return (
    <div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header title="App User List"  />

        <GridComponent
          id="gridcomp"
          width="auto"
          dataSource={orderData}
          allowPaging
          allowSorting
          allowExcelExport
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
        >
          <ColumnsDirective>
            {itemsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Search,
              ContextMenu,
              Page,
              Selection,
              Toolbar,
              Edit,
              Sort,
              Filter,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent>
      </div>
    </div>
  );
};
export default AppUsers;
