import React, { useState, useEffect, useContext } from "react";
import "./vieworder.css";
import { Link ,useNavigate, useParams} from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import axios from "axios";



const AssignOrder = () => {
  const [employee, setEmployee] = useState([]);
  const [filteremployee, setfilteremployee] = useState([]);
  const [emptype, setEmptype] = useState("");
  const [empId, setEmpId] = useState("");
  const handleemptype = (e) => {
    setEmptype(e.target.value);
    filterdata(e.target.value);
  };
  const filterdata = (data) => {
    if (data == "SELF") {
      let data = employee.filter(
        (item) => item.empStatus.toUpperCase() == "SELF"
      );
      setfilteremployee(data);
    } else if (data !== "SELF") {
      let data = employee.filter(
        (item) => item.empStatus.toUpperCase() !== "SELF"
      );
      setfilteremployee(data);
    }
  };
  const handleemp = (e) => {
    setEmpId(e.target.value);
  };

  const fetchEmployee = () => {
    let id = sessionStorage.getItem("id");

    console.log("IDName", id)
    
    fetch(`http://143.244.132.221:4002/api/v1/auth/employee/byDistributorId/${id}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setEmployee(data.data);
      });
  };
  const navigate = useNavigate();
  const { id } = useParams();

  const mainPage = () => {
    navigate("/Orders");
  };
  const API = "http://143.244.132.221:4002/api/v1/order";

  const handleAssign = async () => {
    const payload = {
        orderStatus: "PACKAGING",
        employeeId: empId,
        isEmployee: true,
    };
    await axios
      .put(`${API}/${id}`, payload)
      .then((response) => {
        const SuccessRes = response.data.status == "SUCCESS";
        if (SuccessRes) {
          console.log("handleAssign response", response);
          mainPage();
         
        }
      })
      .catch((error) => {
        console.log("handleAssign response error!", error);
       
      });
  };

  useEffect(() => {
    fetchEmployee();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <div class="fixbox">
        <div class="box">
          <div class="flex justify-end active">
            <div class="backIcon">
              <Link to="/orders">
                <AiOutlineRollback />
              </Link>
            </div>
          </div>
          <div class="flex-container">
            <div>
              {/* <h1>dhfsjdfsdkfdkf</h1> */}
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                Vidya Shirke assign
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                mohit@gmail.com
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                7218650239
              </h6>
            </div>

            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                KUDAL
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                sitaramteli83@gmail.com
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                7218650239
              </h6>
            </div>
          </div>
          <hr class="new1"></hr>
          <div className="flex-container">
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER DATE
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                2022-05-28 18:53:57
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER NO.
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                WO20220528185357141
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER STATUS
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                Pending
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER TO
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                Vidya shirke morya redency e wing second floor room no 202, near
                vad Ganesh Mandir pinguli kudal sawantwadi highway XPM3+MP6,
                Hathkhamba-Goa Rd, Tembdhurinagar, Maharashtra 416520, India
              </h6>
            </div>
          </div>
        </div>
        <div class="tablefont">
          <table class="min-w-full auto">
            <thead class="bg-white border-b">
              <tr>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900  text-left"
                >
                  MERCHANT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CATEGORY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CLASS.
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  SUB CLASS.
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  ITEMS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  UNIT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  QTY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  AMOUNT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  Burger Nest
                </td>

                <td
                  colSpan={3}
                  class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"
                >
                  --- PIZZA/SANDWICH/BURGER PIZZA/SANDWICH/BURGER
                </td>
                {/* <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td> */}
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  Chicken Burger
                </td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  Pcs
                </td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  1
                </td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  65
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  65
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  SUB TOTAL
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  650
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  DISCOUNT AMOUNT
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  0
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  ORIZ COMMISSION
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  4
                </td>
              </tr>

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  GST
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  0
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-md font-medium text-gray-900">
                  DELIVERY AMOUNT
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  0
                </td>
              </tr>

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  FINAL AMOUNT
                </td>
                <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
                  61
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footerfont">
          <div class="flex-container">
            <div>
              <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
                Assign Employee to Order :
              </h4>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 text-blue-600">
                Order Pick Up Time :
              </h6>
              <form>
                <div class="relative z-0 p-2 w-full mb-6 group">
                  <label>Employee Type</label>
                  <select
                    class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                    name="categoryId"
                    value={emptype}
                    onChange={handleemptype}
                    required
                  >
                    <option selected>Select Value</option>
                    {employee.map((typeget) => (
                      <option value={typeget.empStatus} id={typeget._id}>
                        {typeget.empStatus}
                      </option>
                    ))}
                  </select>
                  {/* {data.categoryId ? null : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter this Field
              </p>
            )} */}
                </div>
                <div class="relative z-0 p-2 w-full mb-6 group">
                  <label>Employees</label>
                  <select
                    class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    aria-label="Default select example"
                    name="categoryId"
                    value={empId}
                    onChange={handleemp}
                    required
                  >
                    <option selected>Select Value</option>
                    {filteremployee.map((typeget) => (
                      <option value={typeget._id} id={typeget._id}>
                        {typeget.firstName} &nbsp;
                        {typeget.lastName} &nbsp; &nbsp;
                        {typeget.status}
                      </option>
                    ))}
                  </select>
                  {/* {data.categoryId ? null : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter this Field
              </p>
            )} */}
                </div>
                <div class="flex space-x-2 ">
                  <button
                    type="button"
                      onClick={() => handleAssign()}
                    class="addButton"
                  >
                    Assign
                  </button>
                </div>
              </form>
            </div>
            <div class="right">
              <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
                Total Amount
              </h4>
              <h3 class="font-large leading-tight text-3xl mt-0 mb-2 text-green-800">
                <span class="text-xxl inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded-full">
                  618.00
                </span>
              </h3>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 text-blue-600">
                Taxes Included
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignOrder;
