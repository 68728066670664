import React,  {useState, useEffect} from "react";
import axios from "axios";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { Header } from "../../components";
import { useNavigate,  } from "react-router-dom";
import { itemsGrid, itemsData, gridItemAction, gridPaymentStatus, contextMenuItems } from "./OrderPaymentsConfig";
import {
  AppBar,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button } from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "center",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "center",
  },
}));

// format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
 
    return `${day}/${month}/${year}`;
  };

const OrderPayments = () => {
  const distId = sessionStorage.getItem("id");
  const [orderData, setOrderData] = useState([]);
  function fetchOrders() {
    axios
      .get(`http://143.244.132.221:4002/api/v1/order/user/${distId}`)
      // .get(`http://143.244.132.221:4002/api/v1/order`)
      .then((res) => {
        console.log("hello orderdata 2222", res.data.data);
        setOrderData(res.data.data);
      })
      .catch((err) => {
        console.log("orderdata error",err.message);
      });
  }
  useEffect(() => {
    fetchOrders();
  }, []);
  
  

  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('DELIVERED');
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);


  const addFilterBtn = (status) => {
    setSelectedStatus(status);
    sessionStorage.setItem('selectedStatus', status);
  };

  const filteredOrders = selectedStatus ? orderData.filter((item) => item.orderStatus === selectedStatus): orderData;

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    if(storedPage !== null){
      setCurrentPage(parseInt(storedPage, 10));
    }

    const storedStatus = sessionStorage.getItem('selectedStatus');
    if(storedStatus !== null){
      setSelectedStatus(storedStatus);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(filteredOrders?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(filteredOrders?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };

// format date
// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   const day = date.getDate().toString().padStart(2, '0');
//   const month = (date.getMonth() + 1).toString().padStart(2, '0');
//   const year = date.getFullYear();

//   return `${day}/${month}/${year}`;
// };

  const navigate = useNavigate();

  const filteredData = filteredOrders.map((item) => {
    const userName = item?.userId?.firstName ?? '';
    const order = item?.orderNo ?? '';
  
    return {
      ...item,
      userId: {
        ...item?.userId,
        firstName: userName,
      },
      orderNo: order,
    };
  }).filter((item) => {
    const firstNameMatches = item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    const orderNoMatches = item?.orderNo?.toString().includes(searchQuery);

    return firstNameMatches || orderNoMatches;
  });
  
  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">

      <h3
        style={{ color: "#B2A59B" }}
        class="font-medium leading-tight text-3xl mt-0 mb-2 "
      >
        Total Amount To Pay :{" "}
        <span style={{ color: "#607274" }}> Invalid amount </span> Paid Amount :
        <span style={{ color: "#607274" }}> Invalid amount</span> Balance Amount :
        <span style={{ color: "#607274" }}>Invalid amount</span>
      </h3>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: "rgb(0, 60, 126)",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
       <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
       Order Payment List ({filteredOrders ? filteredOrders.length : 0})
          </h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>
 
      <div className="flex space-x-2 m-4 justify-start">
        <button type="button" onClick={() => addFilterBtn('DELIVERED')}
         className={`order_addBtn ${selectedStatus === 'DELIVERED' ? 'active_order_addBtn' : ''}`}>
        DELIVERED
        </button>
        <button type="button" onClick={() => addFilterBtn('COMPLETED')} 
        className={`order_addBtn ${selectedStatus === 'COMPLETED' ? 'active_order_addBtn' : ''}`}>
          COMPLETED
        </button>
      </div>

     {console.log("orderData", orderData)}
      <Divider />
      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell className={classes.cellRow}>Id</TableCell> */}
              <TableCell className={classes.cellRow}>Order No</TableCell>
              <TableCell className={classes.cellRow}>Beneficiary Name</TableCell>
              <TableCell className={classes.cellRow}>Payment Amount</TableCell>
              <TableCell className={classes.cellRow}>Payment Date</TableCell>
              <TableCell className={classes.cellRow}>Payment Status</TableCell>
              <TableCell className={classes.cellRow}>Payment mode</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderData?.length > 0 ? (
              currentPageData.filter((item) => selectedStatus ? item.orderStatus === selectedStatus : true)
              .map((item, i) => (
                <TableRow key={item._id}>
                  {/* <TableCell className={classes.cell}>{i+1}</TableCell> */}
                  <TableCell className={classes.cell}>{item?.orderNo}</TableCell>
                  <TableCell className={classes.cell}>{item?.userId?.firstName}</TableCell>
                  <TableCell className={classes.cell}>{item?.totalPrice}</TableCell>
                  <TableCell className={classes.cell}>{formatDate(item?.createdAt)}</TableCell>
                  <TableCell className={classes.cell}>{gridPaymentStatus(item)}</TableCell>
                  <TableCell className={classes.cell}>{item?.paymentMode}</TableCell>
                  <TableCell className={classes.cell}>{gridItemAction(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filteredData?.length > 0 ? (
          <TablePagination
            count={filteredData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>

      {/* <GridComponent
          id="gridcomp"
          width="auto"
          // dataSource={itemsData}
          dataSource={orderData}
          allowPaging
          allowSorting
          allowExcelExport
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
        >
          <ColumnsDirective>
            {itemsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Search,
              ContextMenu,
              Page,
              Selection,
              Toolbar,
              Edit,
              Sort,
              Filter,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent> */}
        
      </div>
 
  );
};
export default OrderPayments;
