import React, { useState, useEffect, useContext } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { Button } from ".";
import { userProfileData } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import avatar from "../data/avatar.jpg";
import StateContext from "../contexts/ContextProvider";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  //const { token } = useContext(StateContext);
  //const { currentColor } = useStateContext();
  //const { setAuth } = useContext(StateContext);
  const { setProfileData } = useContext(StateContext);

  const navigate = useNavigate();

  const [data, setData] = useState([""]);
  const logoutColor = "#ff5c8e";

  function fetchProfile() {
    let token =   sessionStorage.getItem("myToken")
    const headers = {
      "x-token": token,
    };
    const getProfileAPI = "http://143.244.132.221:4002/api/v1/auth/get-profile";
    axios
      .get(getProfileAPI, { headers })
      .then((res) => {
        
        setData(res.data.data);
        setProfileData(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  useEffect(() => {
    fetchProfile();
  }, []);

  console.log(data);

  function logout() {
    navigate("/");
    window.sessionStorage.clear();
    window.location.reload();
  }

  function editProfile() {
    navigate("/editprofile");
    <Button
      icon={<MdOutlineCancel />}
      color="rgb(153, 171, 180)"
      bgHoverColor="light-gray"
      size="2xl"
      borderRadius="50%"
    />;
  }

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">
          {data.firstName} {""} {data.lastName}
        </p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={data.avatar}
          alt="user-profile"
        />
        <div>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            {data.role}{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            Firm Name - {data.firmName}{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            Email - {data.email}{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            Pincode - {data.pincode}{" "}
          </p>
        </div>
      </div>
      {/* <div style={{ marginTop: 15 }}></div> */}
      <div className="m-2">
      <button
        onClick={editProfile}
        style={{
                   width: "50%",
          marginLeft: 20,
          backgroundColor: "#8be78b",
          height: 30,
          color: "white",
          borderRadius: 8,
          alignItems: "center",
          alignContent: "center",
        }}
      >
        Edit Profile
      </button>
      {/* <div style={{ marginTop: 15 }}></div> */}
      <button
        onClick={logout}
        style={{
          width: "35%",
          marginLeft: 10,
          backgroundColor: "#ff5c8e",
          height: 30,
          color: "white",
          borderRadius: 8,
          alignItems: "center",
          alignContent: "center",
        }}
      >
        Logout
      </button>
      </div>
      {/* <div>
      {userProfileData.map((item, index) => (
        <div
          key={index}
          className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
        >
          <button
            type="button"
            style={{ color: item.iconColor, backgroundColor: item.iconBg }}
            className=" text-xl rounded-lg p-3 hover:bg-light-gray"
          >
            {item.icon}
          </button>

          <div>
            <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {" "}
              {item.desc}{" "}
            </p>
          </div>
        </div>
      ))}
    </div>
    <div className="mt-5"></div> */}
      {/* <Button
      color="white"
      bgColor={logoutColor}
      text="Logout"
      borderRadius="10px"
      width="full"
      onClick={() => (window.localStorage.clear(), window.location.reload())}
    /> */}
    </div>
  );
};

export default UserProfile;

// import React from 'react';
// import { MdOutlineCancel } from 'react-icons/md';
// import { useNavigate } from 'react-router-dom';
// import { Button } from '.';
// import { userProfileData } from '../data/dummy';
// import { useStateContext } from '../contexts/ContextProvider';
// import avatar from '../data/avatar.jpg';



// const UserProfile = () => {
//   const { currentColor } = useStateContext();

//   const navigate = useNavigate();
// function logout() {
//   navigate("/");
//   window.sessionStorage.clear();
//   window.location.reload();
// }
//   return (
//     <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
//       <div className="flex justify-between items-center">
//         <p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
//         <Button
//           icon={<MdOutlineCancel />}
//           color="rgb(153, 171, 180)"
//           bgHoverColor="light-gray"
//           size="2xl"
//           borderRadius="50%"
//         />
//       </div>
//       <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
//         <img
//           className="rounded-full h-24 w-24"
//           src={avatar}
//           alt="user-profile"
//         />
//         <div>
//           <p className="font-semibold text-xl dark:text-gray-200"> Michael Roberts </p>
//           <p className="text-gray-500 text-sm dark:text-gray-400">  Administrator   </p>
//           <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> info@shop.com </p>
//         </div>
//       </div>
//       <div>
//         {userProfileData.map((item, index) => (
//           <div key={index} className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
//             <button
//               type="button"
//               style={{ color: item.iconColor, backgroundColor: item.iconBg }}
//               className=" text-xl rounded-lg p-3 hover:bg-light-gray"
//             >
//               {item.icon}
//             </button>

//             <div>
//               <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
//               <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
//             </div>
//           </div>
//         ))}
//       </div>
//       <button
//         onClick={logout}
//         style={{
//           width: "100%",
//           backgroundColor: "#ff5c8e",
//           height: 50,
//           color: "white",
//           borderRadius: 8,
//           alignItems: "center",
//           alignContent: "center",
//         }}
//       >
//         Logout
//       </button>
//     </div>

//   );
// };

// export default UserProfile;
