import React from "react";
import { useNavigate } from "react-router-dom";

const EditGroupPromotionalDiscount = () => {
  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/grouppromotionaldiscount");
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="backButton" onClick={() => mainPage()}>
          Back
        </button>
        <button type="button" class="editButton">
          Edit
        </button>
      </div>
      <form>
        <div className="grid xl:grid-cols-2  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant Type</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="1">Restraunt</option>
              <option value="2">Pharmacy</option>
              <option selected="3">Hotel</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Merchant(S)</label>
            <select
              class="form-select appearance-none
            block
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
            >
              <option value="1">Hotel Avkar</option>
              <option value="2">Blue Zinger</option>
              <option selected="3">White Palace</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="discountpercentage"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Discount Percentage
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="discountpercentage"
              value="100"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="discountamount"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Max Discount Amount
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="discountamount"
              value="100"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="orderamount"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Min Order Amount
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="orderamount"
              value="1000"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="order"
              class="form-label inline-block mb-2 text-gray-700"
            >
              No Of Order
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="order"
              value="5"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="validityfrom"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Validity From
            </label>
            <input
              type="datetime-local"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="validityfrom"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="validityto"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Validity To
            </label>
            <input
              type="datetime-local"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="validityto"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="image"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Image
            </label>
            <input
              type="file"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="image"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditGroupPromotionalDiscount;
