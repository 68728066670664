import { Link } from "react-router-dom";
import React, { useContext, useState } from "react";
import { AiOutlineFolderView } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import ConfirmModal from "../ConfirmModal";
import CancelModal from "../CancelModal";
import axios from "axios";
import OrderDelete from "./OrderDelete";
import StateContext from "../../../contexts/ContextProvider";
import AssignPopup from "../AssignPopup";
import AssignedPopup from "../AssignedPopup";
import ShipPopup from "../ShipPopup";

export const gridItemAction = (itemId) => (
  <div className="flex items-center justify-center gap-2">
  <h1
    style={{ color: "#03c9d7", display: "flex" }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
    {/* <Link
      style={{ marginRight: "auto" }}
      to={`/employeewiseorders/view/${itemId._id}`}
    >
      <FiEye />
    </Link> */}
    <Link
      style={{ marginRight: "auto" }}
      to={`/order/view/${itemId._id}`}
    >
      <FiEye />
    </Link>
  </h1>


  <button
    style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
    className="font-small leading-tight text-xl mt-0 mb-2 "
  >
<OrderDelete itemId={itemId._id} />
  </button>
</div>
);

const itemView = (itemId) => {
  const { setViewData } = useContext(StateContext);
  const viewId = itemId._id;
  console.log(viewId);
  setViewData(itemId);
  console.log("ppppyyyy", itemId);
};

const gridCancel = () => (
  <div class="flex space-x-2 justify-center">
    <h6
      style={{ color: "#ff5c8e", display: "flex" }}
      class="font-medium leading-tight text-3xl mt-0 mb-2 "
    >
      <CancelModal />
    </h6>
  </div>
);

export const gridConfirm = (props) => (
  <div class="flex space-x-2 justify-center">
    <h6
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-3xl mt-0 mb-2 "
    >
     
      {props.orderStatus == "PENDING" && <ConfirmModal props={props} />}
      {props.orderStatus == "PROCESSING" && (
        <div>
          {/* <Link to={`/orders/assign/${props._id}`}>
            <button
              style={{ color: "white", backgroundColor: "#8be78b" }}
              type="button"
              class="px-2
      py-2
      bg-blue-600
      text-white
      font-small
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
            >
              Assign
            </button>
          </Link> */}
          {/* <BsFillPencilFill onClick={() => itemEdit(itemId)} /> */}
          <button
              style={{ color: "white", backgroundColor: "#8be78b" }}
              type="button"
              class="px-2
      py-2
      bg-blue-600
      text-white
      font-small
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
      id="OrderBnt"
            >
              <AssignPopup id={props._id}/>
             
            </button>
        </div>
      )}
      {props.orderStatus == "PACKAGING" && (
        <div>
          <button
            style={{ color: "white", backgroundColor: "#8be78b" }}
            type="button"
            class="px-2
      py-2
      bg-blue-600
      text-white
      font-small
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
          >
             <AssignedPopup props={props}/>
          </button>
        </div>
      )}
      {props.orderStatus == "SHIPPING" && (
        <div>
          <button
            style={{ color: "white", backgroundColor: "#8be78b" }}
            type="button"
            class="px-2
      py-2
      bg-blue-600
      text-white
      font-small
      text-xs
      leading-tight
      uppercase
      rounded
      shadow-md
      hover:bg-blue-700 hover:shadow-lg
      focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
      active:bg-blue-800 active:shadow-lg
      transition
      duration-150
      ease-in-out"
          >
             <ShipPopup props={props}/>
          </button>
        </div>
      )}
 
    </h6>

  </div>
);

const gridStatus = (props) => (
  <button
    type="button"
    style={{ backgroundColor: "03C9D7" }}
    className="text-black py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.orderStatus}
  </button>
);

const gridDate = (props) => {
  const d1 = new Date(props.createdAt);
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  function formatTime(date) {
    var d = new Date(date),
      hour = "" + d.getUTCHours(),
      minute = "" + d.getUTCMinutes(),
      second = d.getUTCSeconds();

    if (minute.length < 2) minute = "0" + minute;
    if (second.length < 2) second = "0" + second;

    return [hour, minute, second].join(":");
  }

  var dateresult = formatDate(d1);
  var timeresult = formatTime(d1);
  return (
    <button
      type="button"
      style={{ backgroundColor: "03C9D7" }}
      className="text-black py-1 px-2 capitalize rounded-2xl text-md"
    >
      {dateresult}
      <br />
      {timeresult}
    </button>
  );
};
const gridorderNo = (props) => (
  <button
    type="button"
    style={{ backgroundColor: "03C9D7" }}
    className="text-black py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.orderNo.toUpperCase()}
  </button>
);

const gridPayment = (props) => (
  <button
    type="button"
    style={{ background: props.PaymentStatusBg }}
    className="text-white py-1 px-2 capitalize rounded-2xl text-md"
  >
    {props.paymentMode}
  </button>
);

export const itemsGrid = [
  {
    field: "orderNo",
    headerText: "Order No",
    isPrimaryKey: true,
    textAlign: "Center",
    template: gridorderNo,
  },

  // {
  //   field: "merchantId.merchantTypeId.domainType",
  //   headerText: "Category",
  //   textAlign: "Center",
  // },
  {
    // field: "date",
    field: "createdAt",
    type:'date',
    format:"E,MMM dd yyyy,hh:mm a",
    headerText: "Date",
    textAlign: "Center",
    // template: gridDate,
  },

  // {
  //   field: "orderno",
  //   headerText: "Order No",
  //   textAlign: "Center",
  // },
  {
    // field: "userId",
    field: "userId.firstName",

    headerText: "Client",
    textAlign: "Center",
  },

  {
    field: "totalPrice",
    headerText: "Amount",
    textAlign: "Center",

  },
  // {
  //   field: "commission",
  //   headerText: "Commission",
  //   textAlign: "Center",
  // },
  // {
  //   field: "pincode",
  //   headerText: "Pincode",
  //   textAlign: "Center",
  // },
  // {
  //   field: "merchantId.firstName",
  //   headerText: "Merchant Name",
  //   textAlign: "Center",
  // },
  // {
  //   field: "distributorId.firstName",
  //   headerText: "Distributor Name",
  //   textAlign: "Center",
  // },
  {
    field: "orderStatus",
    headerText: "Order Status",
    template: gridStatus,
    textAlign: "Center",
  },
  {
    field: "paymentMode",
    headerText: "Payment Mode",
    // template: gridPayment,
    textAlign: "Center",
  },
  {
    field: "paymentStatus",
    headerText: "Payment Status",
    // template: gridPayment,
    textAlign: "Center",
  },
  // {
  //   field: "prescription",
  //   headerText: "Prescription",
  //   textAlign: "Center",
  // },
  {
    field: "confirm",
    headerText: "Order Action",
    textAlign: "Center",
    template: gridConfirm,
  },
  // {
  //   field: "cancel",
  //   headerText: "Cancel",
  //   textAlign: "Center",
  //   template: gridCancel,
  // },
  {
    field: "action",
    headerText: "Actions",
    textAlign: "Center",
    template: gridItemAction,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Active",
    OrderStatusBg: "#03C9D7",
    paymentmode: "Cash",
    PaymentStatus: "Done",
    PaymentStatusBg: "#8BE78B",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 2,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 3,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "canceled",
    OrderStatusBg: "#FF5C8E",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 4,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 5,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 6,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 7,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 8,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 9,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 10,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 11,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 12,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 13,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 14,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 15,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 16,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 17,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 18,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 19,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
  {
    id: 20,
    category: "Restraunt",
    orderno: "F02",
    client: "Ajay Bist",
    date: "2022-05-05 18:20:00",
    amount: 284,
    commission: 19.0,
    OrderStatus: "Complete",
    OrderStatusBg: "#8BE78B",
    paymentmode: "Cash",
    PaymentStatus: "Pending",
    PaymentStatusBg: "#FF5C8E",
    prescription: "",
    pincode: "400111",
    merchantname: "Prabhas",
    distributorname: "Mahesh",
  },
];
