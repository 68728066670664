import React from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Selection,
  Edit,
  Toolbar,
  Sort,
  Filter,
  Resize,
  ContextMenu,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import "../../pages/view.css";
import { itemsData, contextMenuItems, itemsGrid } from "./GroupConfig";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";

const GroupPromotionalDiscount = () => {
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/grouppromotionaldiscount/add");
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header title="Group Promotinal Discount List" />
      <div class="flex space-x-2 m-4 justify-end">
        <button type="button" onClick={() => addPage()} class="addButton">
          New Group Promotinal Discount
        </button>
      </div>
      <GridComponent
        id="gridcomp"
        width="auto"
        dataSource={itemsData}
        allowPaging
        allowSorting
        allowExcelExport
        pageSettings={{ pageCount: 5 }}
        selectionSettings={selectionsettings}
        toolbar={toolbarOptions}
        allowPdfExport
        contextMenuItems={contextMenuItems}
        editSettings={editing}
      >
        <ColumnsDirective>
          {itemsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Resize,
            Search,
            ContextMenu,
            Page,
            Selection,
            Toolbar,
            Edit,
            Sort,
            Filter,
            ExcelExport,
            PdfExport,
          ]}
        />
      </GridComponent>
    </div>
  );
};
export default GroupPromotionalDiscount;
